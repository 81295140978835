import { createContext } from 'react';
import { AuthContextType } from '../utils/types';

export const AuthContext = createContext<AuthContextType>({
  auth: {
    authFetched: false,
    isFetchingAuth: false,
    hasAuth: false,
    id: '',
  },
  getAccess: async () => {},
  login: async () => 'Please try again',
});
