import { isAxiosError } from 'axios';
import { APIError } from '../types';

function isAPIError(err: unknown): err is APIError {
  if (!isAxiosError(err)) return false;
  return typeof err.response?.data.error === 'string';
}

export function getErrorMessage(err: unknown) {
  if (isAPIError(err)) return err.response.data.error;
  if (isAxiosError(err)) return err.message;
  return JSON.stringify(err);
}
