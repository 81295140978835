import axios, { Canceler } from 'axios';

// -------------------------- urls -------------------------- //

const urls = [
  { baseURL: 'https://ryannono.up.railway.app/api' },
  { baseURL: 'http://localhost:3001/api' },
] as const;

const { location } = window;

export const BASE_URL = location.protocol.concat(
  '//',
  location.hostname,
  location.port ? `:${location.port}` : ''
);

export const BASE_API_URL =
  window.location.protocol === 'https:'
    ? 'https://ryannono.up.railway.app/api'
    : urls[1].baseURL;

// -------------------- axios - instance --------------------- //

/**
 * Creates a new CancelToken which can be provided to an Axios request to
 * allow cancellation of the request. The provided callback is called with
 * the `cancel` function that can be used to cancel the request.
 *
 * @param {function(Canceler): void} callback - A function that receives the
 *        `cancel` function as an argument. This `cancel` function can be
 *        called to cancel the Axios request associated with the created token.
 * @returns {CancelToken} The created CancelToken.
 *
 * @example
 * const source = getCancelToken(c => cancel = c);
 * axiosInstance.get('/some-endpoint', { cancelToken: source.token });
 * // To cancel the request at some point:
 * cancel('Request canceled');
 */
export function getCancelToken(callback: (c: Canceler) => void) {
  return new axios.CancelToken((c) => callback(c));
}

/**
 * Exports the static Axios object. This can be useful in cases where one
 * might want to use Axios without the predefined configurations of the
 * `axiosInstance`.
 */
export const axiosStatic = axios;

// Create an instance of Axios with some default configurations.
const axiosInstance = axios.create();
// Ensure that credentials such as cookies, authorization headers, etc., are sent with every request.
axiosInstance.defaults.withCredentials = true;
// Set the default base URL for API requests.
axiosInstance.defaults.baseURL = BASE_API_URL;

/**
 * The customized Axios instance with predefined configurations.
 * This instance has default configurations to:
 * - Send requests with credentials.
 * - Use `BASE_API_URL` as the default base URL.
 */
export default axiosInstance;
