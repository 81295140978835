import { Action, Auth } from './types';

/**
 * Reducer function for handling authentication actions.
 *
 * This function processes the various actions related to authentication and returns the new state accordingly.
 *
 * @param state {ReducerAuth} - The current authentication state (without 'isFetchingAuth')
 * @param action {Action} - The action to process, one of the following:
 *   - 'SET_AUTH_FETCHED': Set the `authFetched` flag
 *   - 'SET_ID': Set the user's unique identification and `hasAuth` flag if the ID is not empty
 *   - 'RESET_AUTH': Reset the entire authentication state
 * @return {Auth} - The new authentication state after processing the action
 */
export function authReducer(state: Auth, action: Action): Auth {
  switch (action.type) {
    case 'INITIATE_AUTH_FETCH':
      return { ...state, isFetchingAuth: true };
    case 'SET_AUTH_FETCHED':
      return { ...state, authFetched: action.payload, isFetchingAuth: false };
    case 'SET_ID':
      return { ...state, id: action.payload, hasAuth: action.payload !== '' };
    case 'RESET_AUTH':
      return {
        authFetched: false,
        isFetchingAuth: false,
        hasAuth: false,
        id: '',
      };
    default:
      return state;
  }
}
