// Types
import { Direction } from '../types';

/**
 * `isDirection` Function
 *
 * @remarks
 * The `isDirection` function acts as a type guard, determining whether a given
 * string value corresponds to a valid `Direction` type (i.e., one of the possible
 * cardinal directions: `bottom`, `left`, `top`, `right`).
 *
 * Key Features:
 * 1. **Type Guarding** - Provides runtime checks for type safety, ensuring the
 *    passed string matches one of the predefined direction values.
 *
 * 2. **Versatility** - Can be used to verify input values before executing
 *    logic dependent on a valid direction.
 *
 * @param string - The value to check if it's a valid `Direction`.
 *
 * @returns boolean - `true` if the string is a valid direction, `false` otherwise.
 *
 * @example
 * ```
 * // To check if a string is a valid direction
 * const validDirection = isDirection('bottom'); // true
 * ```
 *
 * ## Usage Notes:
 * - This function is particularly useful when working with external inputs where
 *   the value's type can be ambiguous.
 * - It ensures that operations reliant on a valid direction can be executed
 *   without unexpected behaviors.
 */
export function isDirection(string: unknown): string is Direction {
  const direction: Direction[] = ['bottom', 'left', 'top', 'right'];
  return direction.includes(string as Direction);
}
