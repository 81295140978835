// External libraries
import { MotionProps, Variants } from 'framer-motion';

/**
 * Animation properties for navigation elements.
 *
 * @remarks
 * - Utilizes ease-in easing for a smoother beginning of the animation.
 * - Animation duration is set to 1.5 seconds.
 * - Initially, the navigation element is fully transparent.
 * - On animation completion, the navigation element reaches full opacity.
 *
 * @example
 * ```tsx
 * <motion.div {...navAnimation}>
 *   ... Navigation content ...
 * </motion.div>
 * ```
 */
export const globalNavAnimation: MotionProps = {
  transition: { ease: 'easeIn', duration: 1.5 },
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

/**
 * `centerSectionVariants` Animation Variants
 *
 * @remarks
 * These variants define the animation states for the center section
 * of the navigation in different scenarios. This uses the `framer-motion`
 * library to animate the opacity and the vertical position (y-axis) of the center section.
 *
 * - `mobileHide`: Represents the hidden state of the center section in mobile view.
 * - `mobileShow`: Represents the visible state of the center section in mobile view.
 * - `hide`: Represents the hidden state of the center section in other contexts (e.g., desktop).
 * - `show`: Represents the default visible state.
 * - `initialShow`: Represents the initial visible state, often used for the very first render or animation.
 *
 * Each variant consists of:
 * - `opacity`: Controls the visibility. Ranges from 0 (invisible) to 1 (fully visible).
 * - `y`: Controls the vertical position. Positive values move the element down, and negative values move it up.
 * - `transition`: Specifies the duration and other dynamics of the animation.
 */
export const centerSectionVariants: Variants = {
  mobileHide: {
    opacity: 0,
    y: '-20px',
    transition: {
      duration: 0.3,
      when: 'afterChildren',
      visibility: { delay: 0.3, duration: 0 }, // Delay matches the opacity transition duration
    },
    visibility: 'hidden',
  },
  mobileShow: {
    opacity: 1,
    y: '0px',
    transition: {
      duration: 0.75,
      when: 'beforeChildren',
      visibility: { delay: 0, duration: 0 }, // Immediate change to visible before opacity starts
    },
    visibility: 'visible',
  },
  hide: {
    opacity: 0,
    y: '-20px',
    transition: {
      duration: 0.3,
      when: 'afterChildren',
      visibility: { delay: 0.3, duration: 0 }, // Delay matches the opacity transition duration
    },
    visibility: 'hidden',
  },
  show: {
    opacity: 1,
    y: '0px',
    transition: {
      duration: 1.5,
      when: 'beforeChildren',
      visibility: { delay: 0, duration: 0 }, // Immediate change to visible before opacity starts
    },
    visibility: 'visible',
  },
  initialShow: {
    opacity: 1,
    y: '0px',
    transition: {
      duration: 3,
      when: 'beforeChildren',
      visibility: { delay: 0, duration: 0 }, // Immediate change to visible before opacity starts
    },
    visibility: 'visible',
  },
};

/**
 * Animation properties for side sections on a page.
 *
 * @remarks
 * - Utilizes ease-in easing for a smoother beginning of the animation.
 * - Animation duration is set to 0.5 seconds.
 * - Initially, the side section is fully transparent.
 * - On animation completion, the side section reaches full opacity.
 *
 * @example
 * ```tsx
 * <motion.div {...sideSectionAnimation}>
 *   ... Side section content ...
 * </motion.div>
 * ```
 */
export const sideSectionAnimation: MotionProps = {
  transition: { ease: 'easeIn', duration: 0.5 },
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};
