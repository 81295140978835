// External libraries
import { memo, useRef } from 'react';
import LottiePlayer, { LottieRefCurrentProps } from 'lottie-react';

// Components
import Chip from '../chip/chip';

// Hooks
import useMenuToggle from './hook/useMenuToggle';

// Types
import { MenuToggleProps } from './hook/useMenuToggle';

// Assets
import HamburgerAnimation from '../../assets/lotties/hamburgerMenuLottie.json';

// Styles
import './hamburgerButton.css';

/**
 * `HamburgerButton` Component
 *
 * @remarks
 * The `HamburgerButton` component serves as an interactive UI button typically
 * used for toggling a side menu or dropdown menu in mobile or responsive views.
 *
 * This button utilizes the Lottie animation library to display a visually appealing
 * toggle effect when clicked. The actual toggling logic and behavior are managed by
 * the `useMenuToggle` hook, while the visual animations are handled by the `LottiePlayer` component.
 *
 * Key Features:
 * 1. **Interactive Animation**: Incorporates the `LottiePlayer` component to play a
 *    predefined hamburger menu animation.
 *
 * 2. **Reusable Logic**: Utilizes the `useMenuToggle` hook for managing the open/close
 *    state of a related menu.
 *
 * 3. **Highly Configurable**: Through its props, this component can be linked to different
 *    menu implementations, making it adaptable to varied contexts.
 *
 * @param props - The properties required by the component.
 * @param props.menuRef - A reference to the associated menu that the button toggles.
 * @param props.controls - Control functions that define how the menu should be shown,
 *                         hidden, or its current state verified.
 *
 * @returns JSX.Element - The rendered hamburger button.
 *
 * @example
 * ```tsx
 * <HamburgerButton menuRef={myMenuRef} controls={menuControls} />
 * ```
 */
function HamburgerButton({
  menuRef,
  controls,
}: {
  menuRef: MenuToggleProps['menuRef'];
  controls: MenuToggleProps['controls'];
}) {
  // ref
  const playerRef = useRef<LottieRefCurrentProps>(null);

  // toggle
  const { toggleMenu } = useMenuToggle({ menuRef, controls });

  return (
    <Chip use="button" className="chip lottie" onClick={toggleMenu}>
      <LottiePlayer
        animationData={HamburgerAnimation}
        autoplay={false}
        loop={false}
        lottieRef={playerRef}
        className="player"
      />
    </Chip>
  );
}

export default memo(HamburgerButton);
