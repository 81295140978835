// External libraries
import { MutableRefObject, RefObject } from 'react';
import { AnimationControls } from 'framer-motion';

// Utils
import { getAnimationFunctions } from './animationFunctions';
import { handleEventListeners } from './handleEventListeners';

/**
 * React effect function to manage tooltip events on its parent element.
 * This function sets up the event listeners for tooltip behavior (e.g., hover
 * or click) on its parent element. Upon cleanup (i.e., when the tooltip
 * unmounts or dependencies change), it will remove those listeners.
 *
 * @param {HTMLElement} node - The direct element this effect is applied to.
 *        Typically, this is the tooltip container.
 * @param {MutableRefObject<Set<HTMLElement>>} listeningElementsSet -
 *        A mutable ref object containing a set of elements that are listening
 *        to tooltip events.
 * @param {RefObject<HTMLDivElement>} tooltipRef - Reference to the tooltip
 *        content, usually used within animation functions.
 * @param {AnimationControls} controls - Animation controls, often sourced
 *        from Framer Motion's useAnimation() to control tooltip animations.
 * @param {boolean} desktop - Determines if the viewport is desktop-sized.
 *        Influences the type of events attached to the `hoverElement`.
 *
 * @returns {VoidFunction | void} - Returns a cleanup function that removes
 *          event listeners, ensuring the tooltip behaves correctly during
 *          re-renders or when it's removed from the DOM.
 */
export function tooltipRefEffect(
  node: HTMLElement,
  listeningElementsSet: MutableRefObject<Set<HTMLElement>>,
  tooltipRef: RefObject<HTMLDivElement>,
  controls: AnimationControls,
  desktop: boolean
) {
  const hoverElement = node.parentElement;

  if (!hoverElement) return;

  const animationFunctions = getAnimationFunctions(controls, tooltipRef);

  handleEventListeners(
    hoverElement,
    desktop,
    animationFunctions,
    'add',
    listeningElementsSet
  );

  return () =>
    handleEventListeners(
      hoverElement,
      desktop,
      animationFunctions,
      'remove',
      listeningElementsSet
    );
}
