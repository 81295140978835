import { cubicBezier } from 'framer-motion';
import { VerticalDirection } from './types';

/**
 * `fadeIntoView` is a function that generates animation properties for creating a fade-in effect
 * as an element comes into view. The animation is configured to start with an initial state (opacity and position)
 * and transition to a final state (opacity 1 and original position) when the element is in view.
 *
 * @function
 * @param {string} fadeDirection - The direction of the initial translation. Possible values: 'top', 'bottom'.
 * @param {number|string} [translatePercentage='default'] - The percentage of translation in the initial state.
 *    If 'default' is passed, the initial translation will be set to 10%.
 * @param {number} [delay=0] - The delay before the animation starts, in seconds.
 * @param {boolean} [initialVisibility=false] - A boolean to determine if the element should be visible in its initial state.
 * @returns {Object} An object containing animation properties to create a fade-in effect.
 *
 * @example
 * // usage with a 'motion.div' from 'framer-motion'
 * <motion.div {...fadeIntoView('top', 20, 0.5, false)}>Fade In</motion.div>
 */
export function fadeIntoView({
  fadeDirection,
  translatePercentage = 10,
  delay = 0,
  margin = '0px',
  initialVisibility = false,
  duration = 1.5,
}: FadeProps) {
  return {
    initial: {
      opacity: initialVisibility === true ? 1 : 0,
      translateY: `${
        fadeDirection === 'bottom' ? '-' : '+'
      }${translatePercentage}%`,
    },
    whileInView: { opacity: 1, translateY: 0 },
    viewport: {
      once: true,
      margin,
    },
    transition: {
      ease: 'easeInOut',
      duration,
      delay,
    },
  };
}

export type FadeProps = {
  fadeDirection: VerticalDirection;
  translatePercentage?: number;
  delay?: number;
  margin?: string;
  initialVisibility?: boolean;
  duration?: number;
};

/**
 * A cubic-bezier function for easing animations, defined with four control points.
 * This function represents a standard cubic bezier curve used in CSS animations.
 *
 * @constant
 * @type {function}
 */
export const ease = cubicBezier(0.25, 0.1, 0.25, 1);
