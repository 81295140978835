// External libraries
import { AnimationControls } from 'framer-motion';
import { RefObject } from 'react';

/**
 * `startHoverAnimation`
 * Initiates the hover animation for a tooltip or other component.
 * It triggers both the 'hover' and 'fadeIn' animations using the provided animation controls.
 *
 * @param controls - The framer-motion animation controls responsible for starting animations.
 */
export function startHoverAnimation(controls: AnimationControls) {
  controls.start('hover');
  controls.start('fadeIn');
}

/**
 * `maintainHoverAnimation`
 * Maintains the hover animation for a tooltip or other component.
 * It ensures that the component remains in its 'rest' state.
 *
 * @param controls - The framer-motion animation controls responsible for maintaining animations.
 */
export function maintainHoverAnimation(controls: AnimationControls) {
  controls.start('rest');
}

/**
 * `resetAnimation`
 * Resets the animation of a tooltip or other component to its initial state.
 *
 * @param controls - The framer-motion animation controls responsible for resetting animations.
 */
export function resetAnimation(controls: AnimationControls) {
  controls.start('initial');
}

/**
 * `toggleAnimation`
 * Toggles the visibility and animation of a tooltip. If the tooltip is currently visible,
 * it will be reset to its initial state. Otherwise, the hover animation will be initiated.
 *
 * @param tooltipRef - Reference to the HTML element of the tooltip.
 * @param controls - The framer-motion animation controls responsible for toggling animations.
 */
export function toggleAnimation(
  tooltipRef: RefObject<HTMLDivElement>,
  controls: AnimationControls
) {
  if (!tooltipRef.current) return;
  if (tooltipRef.current.style.visibility === 'visible') {
    resetAnimation(controls);
  } else {
    startHoverAnimation(controls);
  }
}

/**
 * `getAnimationFunctions`
 * Returns a set of animation functions tailored to specific animation controls and a tooltip reference.
 * This ensures encapsulation and easier reuse of animation functions for different tooltips.
 *
 * @param controls - The framer-motion animation controls to be used for the animations.
 * @param tooltipRef - Reference to the HTML element of the tooltip.
 * @returns A set of animation functions.
 */
export function getAnimationFunctions(
  controls: AnimationControls,
  tooltipRef: RefObject<HTMLDivElement>
) {
  return {
    start: () => startHoverAnimation(controls),
    maintain: () => maintainHoverAnimation(controls),
    reset: () => resetAnimation(controls),
    toggle: () => toggleAnimation(tooltipRef, controls),
  };
}

/**
 * `TooltipAnimationFunctions`
 * Type definition for a set of animation functions commonly used for tooltips.
 * This includes starting the animation, maintaining it, resetting it, and toggling it.
 */
export type TooltipAnimationFunctions = {
  start: VoidFunction;
  maintain: VoidFunction;
  reset: VoidFunction;
  toggle: VoidFunction;
};
