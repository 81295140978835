// Lib
import axiosInstance from '../../../lib/axios';

// Utils
import { getErrorMessage } from '../../../utils/functions/getErrorMessage';
import { Action } from '../utils/types';

/**
 * Initiates the login process with the provided password.
 *
 * @remarks
 * This function handles the user login process. If a login attempt is already in progress
 * (indicated by `isFetchingAuth`), the function returns `null` to prevent multiple concurrent attempts.
 *
 * Upon initiating the login process, the function dispatches actions to reset any existing
 * authentication data and then indicate the start of a new login attempt.
 *
 * It then makes an HTTP POST request to the `/auth/login` endpoint, sending the password for
 * authentication. On successful login, the function dispatches a 'SET_ID' action to store
 * the received ID. If the login attempt fails, the function dispatches a 'SET_ID' action
 * with an empty string to clear any previously stored ID and returns the error message.
 *
 * After the login attempt (success or failure), the function dispatches a 'SET_AUTH_FETCHED'
 * action to signal the end of the authentication process.
 *
 * @param password - The password for user login.
 * @param isFetchingAuth - Flag indicating if an authentication/login process is currently active.
 * @param dispatch - Dispatch function for managing the authentication state.
 *
 * @returns Null on a successful login or an error message string on failure.
 *
 * @example
 * ```tsx
 * const password = "user_password";
 * const isFetchingAuth = false;
 * const errorMsg = await login(password, isFetchingAuth, dispatch);
 * if (errorMsg) {
 *   console.error("Login failed:", errorMsg);
 * }
 * ```
 */
export async function login(
  password: string,
  isFetchingAuth: boolean,
  dispatch: React.Dispatch<Action>
) {
  if (isFetchingAuth) return null;

  dispatch({ type: 'RESET_AUTH' });
  dispatch({ type: 'INITIATE_AUTH_FETCH' });

  try {
    const { data: id } = await axiosInstance.post('/auth/login', { password });
    dispatch({ type: 'SET_ID', payload: id });
    return null;
  } catch (loginErr) {
    dispatch({ type: 'SET_ID', payload: '' }); // On error, reset ID
    return getErrorMessage(loginErr);
  } finally {
    dispatch({ type: 'SET_AUTH_FETCHED', payload: true });
  }
}
