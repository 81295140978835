// External libraries
import { MutableRefObject, RefObject } from 'react';

// Utils
import { loadImagesBetween } from './loadImagesBetween';

/**
 * Initiates the loading of images between the target element and the current
 * scroll position. If a previous loading operation was in progress (controlled
 * by the controllerRef), it gets aborted before starting the new load operation.
 *
 * @template Target - Type of the target element, which extends from HTMLElement.
 *
 * @param {MutableRefObject<AbortController | null>} controllerRef - A reference
 *        to an AbortController instance. Used to abort any previous load operation.
 * @param {RefObject<Target>} targetRef - A reference to the target element from
 *        which images should start loading up to the current scroll position.
 * @param {number} offset - A numeric value indicating the offset position from
 *        the target element to enhance loading precision.
 *
 * @returns {Promise<void>} A promise which resolves when the loading operation
 *          is complete.
 */
export async function startLoad<Target extends HTMLElement>(
  controllerRef: MutableRefObject<AbortController | null>,
  targetRef: RefObject<Target>,
  offset: number
) {
  controllerRef.current?.abort();
  controllerRef.current = new AbortController();
  const abortSignal = controllerRef.current.signal;

  if (targetRef.current) {
    await loadImagesBetween(targetRef.current, abortSignal, offset);
  }
}
