// External libraries
import { useContext } from 'react';

// Context
import { CurrentPageContext } from '../context/currentPageContext';

/**
 * `useCurrentPage` Hook
 *
 * @remarks
 * This hook provides access to the current page's context, offering a
 * simple and consistent way to obtain the current page type and any
 * associated properties without the need for prop drilling.
 *
 * Key Features:
 * 1. **Context-based**: The hook is an abstraction over `useContext`,
 *    specifically targeting the `CurrentPageContext` to obtain the current
 *    page information.
 *
 * 2. **Consistent Access**: Any component within the app can use this hook
 *    to consistently access the current page's context, assuming they're
 *    nested within the `CurrentPageContext.Provider`.
 *
 * @returns {CurrentPageType} - An object representing the current page's
 * context (page type and associated properties).
 *
 * @example
 * ```tsx
 * function ComponentWithinApp() {
 *   const currentPage = useCurrentPage();
 *
 *   if (currentPage.pageType === "Home") {
 *     // Render home-specific content or components
 *   }
 * }
 * ```
 *
 * ## Usage Notes:
 * - Ensure that the component using this hook is rendered within the
 *   `CurrentPageContext.Provider` in the component tree.
 *   Otherwise, the hook will return an undefined value.
 * - The returned context contains the `pageType` property, which can
 *   be used to conditionally render components or logic based on the
 *   current page.
 * - This hook abstracts away the need to use `useContext` directly,
 *   providing a more readable and specific way to access the current
 *   page's context.
 */
export default function useCurrentPage() {
  return useContext(CurrentPageContext);
}
