// External libraries
import { motion } from 'framer-motion';
import { useRef } from 'react';

// Components
import Container from '../../components/containers/container/container';
import ContentContainer from '../../components/containers/contentContainer/contentContainer';
import ArrowIcon from '../../components/icon/arrowIcon';
import Chip from '../../components/chip/chip';
import InputForm from '../../components/input/inputForm';
import Label from '../../components/label/label';
import ProjectCard from '../../components/projectCard/projectCard';
import ToolTip from '../../components/toolTip/toolTip';
import Icon from '../../components/icon/icon';

// Hooks
import useDynamicVideos from '../../hooks/useDynamicVideos/useDynamicVideos';
import useNewsletter from '../../features/newsletter/hook/useNewsletter';

// Utils
import { fadeIntoView } from '../../utils/animations';
import { scrollToElement } from '../../utils/functions/scrollToElement';
import { uid } from '../../utils/functions/uid';

// Data
import { projectData } from '../../data/home';

// Styles and assets
import './home.css';

/**
 * `Home` function represents the home page of the application. It uses the useRef hook to get a reference to the 'graphicsBlock' div
 * and the useDynamicVideos custom hook to manage a stateful value for the dynamic videos present in the 'graphicsBlock' div.
 *
 * @component
 *
 * @example
 * <Home />
 *
 * @returns {JSX.Element} Returns a fragment containing the following sections:
 *  - Hero: This section contains the header content and an image.
 *  - Work: This section displays a selection of works.
 *  - Newsletter: This section provides a form for subscribing to the CreativeRank Newsletter and a block containing educational
 *    illustration cards.
 */
export default function Home() {
  const workSectionRef = useRef<HTMLDivElement | null>(null);
  const graphicsBlockRef = useRef<HTMLDivElement | null>(null);
  const setDynamicVideo = useDynamicVideos();
  const { inputRef, subscribe, formState } = useNewsletter();

  return (
    <main id="home">
      <Container noContentContainer id="hero">
        <ContentContainer
          {...fadeIntoView({ fadeDirection: 'bottom' })}
          className="heroContentContainer"
        >
          <img
            style={{ height: '123px' }}
            src="./imgs/home/heroSection/heroDisplayImg.webp"
            alt="Picture of Ryan Nono"
          />

          <ContentContainer className="heroHeadingCTABlock">
            <ContentContainer className="heroHeadingBlock">
              <h1 className="textGradient heroH1">
                Envisioning Tomorrow's Experiences Today
              </h1>
              <h4 className="heroH4">
                Crafting adaptive solutions that thrive. Trusted by companies of
                all sizes — from nimble startups, to fortune 500 enterprises.
              </h4>
            </ContentContainer>
            <Chip
              icon={<ArrowIcon pointDirection="bottom" />}
              iconPosition="left"
              title="Explore the possibilities"
              onClick={() => scrollToElement(workSectionRef.current, -40)}
              use="button"
              className="mainCTA"
            />
          </ContentContainer>
        </ContentContainer>

        <div className="halfElipseContainer">
          <motion.svg
            width="4250"
            height="207"
            viewBox="0 0 4250 207"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="halfElipse"
            {...fadeIntoView({
              fadeDirection: 'top',
              translatePercentage: 40,
              initialVisibility: true,
            })}
          >
            <g clipPath="url(#clip0_778_24822)">
              <path
                d="M4250 2134C4250 1570.41 4026.12 1029.91 3627.6 631.398C3229.09 232.883 2688.59 9.00004 2125 9C1561.42 8.99996 1020.91 232.883 622.398 631.398C223.883 1029.91 8.5099e-05 1570.41 0 2134L2125 2134H4250Z"
                fill="#010101"
              />
            </g>
            <defs>
              <clipPath id="clip0_778_24822">
                <rect width="4250" height="207" fill="white" />
              </clipPath>
            </defs>
          </motion.svg>
        </div>
      </Container>

      <Container
        id="work"
        contentContainerProps={{
          className: 'workContentContainer',
          gap: 'medium',
        }}
        ref={workSectionRef}
      >
        <Label labelText="Selected works" subtle />
        <ContentContainer gridDisplay className="projectGrid">
          {projectData.map((project, index) => (
            <ProjectCard
              key={`projectCard-${index}`}
              projectTitle={project.title}
              projectYear={project.year}
              projectType={project.type}
              companyLogo={
                <Icon
                  iconName={project.icon.name}
                  webp={project.icon.type === 'webp'}
                />
              }
              projectThumbnail={
                <img src={project.thumbnail.src} alt={project.thumbnail.alt} />
              }
              to={project.link}
            />
          ))}
        </ContentContainer>
      </Container>

      <Container
        contentContainerProps={{
          semanticType: 'article',
          className: 'newsletterBannerContainer',
          ...fadeIntoView({ fadeDirection: 'top' }),
        }}
        id="newsletter"
      >
        <ContentContainer className="newsletterContentBlock">
          <ContentContainer className="newsletterHeadingBlock">
            <h4 className="newsletterH4">
              Subscribe to The CreativeRank Newsletter
            </h4>
            <h6 className="newsletterH6">
              Get weekly design resources, tips, and tricks! 👋
            </h6>
          </ContentContainer>
          <ContentContainer>
            <InputForm
              ref={inputRef}
              inputProps={{ placeholder: 'Enter email here', type: 'email' }}
              formProps={{ onSubmit: subscribe }}
              isLoading={formState.isFetching}
            />
            {formState.response && <span>{formState.response}</span>}
          </ContentContainer>
        </ContentContainer>
        <figure className="newsletterGraphicsBlock" ref={graphicsBlockRef}>
          <ToolTip id="newsletterBannerTooltip">
            I make cool and educational illustration cards about design and post
            them on instagram&nbsp;
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/creativerank.co/"
              target="_blank"
            >
              click here to see!
            </a>
          </ToolTip>

          <video
            ref={(node) => setDynamicVideo(node, uid())}
            className="newsletterBannerVideo"
            src="./videos/home/JaredSpoolQuoteVideo.mov"
          />
          <img
            className="newsletterBannerImg"
            src="./imgs/home/newsletterSection/cognitive.svg"
          />
          <video
            ref={(node) => setDynamicVideo(node, uid())}
            className="newsletterBannerVideo"
            src="./videos/home/RichardGrapheQuoteVideo.mov"
          />
          <img
            className="newsletterBannerImg"
            src="./imgs/home/newsletterSection/microInteractions.svg"
          />
        </figure>
      </Container>
    </main>
  );
}
