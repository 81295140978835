// Types
import { CurrentPage } from '../../../../utils/types';

/**
 * `shouldShowRightChip` Function
 *
 * @remarks
 * The `shouldShowRightChip` function determines if the right chip should be
 * displayed based on certain conditions like the device being used, the type of
 * case study being viewed, and the current page type.
 *
 * Key Features:
 * 1. **Responsive Decision Making** - Determines whether the right chip should
 *    be shown based on the user's device (e.g., only on `desktop`).
 *
 * 2. **Page Specific Logic** - Shows the right chip when viewing a preview type
 *    case study or when on the `Password` page.
 *
 * @param device - The current device type (`mobile` or `desktop`).
 * @param caseStudyType - The type of the case study being viewed (e.g., `preview`).
 * @param pageType - The type of the current page being displayed.
 *
 * @returns boolean - `true` if the right chip should be displayed, `false` otherwise.
 *
 * @example
 * ```
 * // To determine if the right chip should be shown on a desktop device
 * const showChip = shouldShowRightChip('desktop', 'full', 'Home'); // true
 * ```
 *
 * ## Usage Notes:
 * - This function consolidates multiple conditions into a single check, making
 *   it easier to handle the display logic for the right chip.
 * - It is vital for ensuring consistent user experience across different devices
 *   and page types.
 */
export function shouldShowRightChip(
  isDektop: boolean,
  caseStudyType: CurrentPage['caseStudyType'],
  pageType: CurrentPage['pageType']
) {
  return isDektop || caseStudyType === 'preview' || pageType === 'Password';
}
