// External librairies
import { useRef } from 'react';

// Hooks
import { useRefCallback } from '../useRefCallback/useRefCallback';

// Types
import { MultiNode } from '../useRefCallback/utils/types';

// Utils
import { getDynamicVideoObserver } from './utils/getDynamicVideoObserver';

/**
 * `useDynamicVideos` Hook
 *
 * This React hook leverages the IntersectionObserver to automatically play
 * and pause video elements based on their visibility in the viewport. When a
 * video element is fully visible, it plays, and when it's not, it pauses.
 *
 * The hook returns a callback function which can be passed to a ref callback
 * for a video element.
 *
 * @returns A function to observe an HTMLVideoElement and play or pause it
 * based on visibility.
 *
 * @example
 * ```tsx
 * const setDynamicVideo = useDynamicVideos();
 *
 * return <video ref={setDynamicVideo} src="video.mp4" />;
 * ```
 */
export default function useDynamicVideos() {
  const intersectionObserverRef = useRef<IntersectionObserver | null>();

  const setDynamicVideo = useRefCallback<HTMLVideoElement, MultiNode>(
    (node) => {
      if (!intersectionObserverRef.current) {
        intersectionObserverRef.current = getDynamicVideoObserver();
      }
      intersectionObserverRef.current.observe(node);

      return () => intersectionObserverRef.current?.unobserve(node);
    },
    []
  );

  return setDynamicVideo;
}
