/**
 * `generateURL` Function
 *
 * @remarks
 * The `generateURL` function is responsible for dynamically generating URLs based on a given navigation link name.
 *
 * Here's the general logic of the function:
 * - If the provided `navLinkName` is 'Work' or 'Newsletter', the function returns a URL fragment (`/#<lowercased-name>`).
 * - If the provided `navLinkName` is "Let's connect", the function returns a LinkedIn profile URL.
 * - For all other link names, the function returns a URL with the lowercased link name (`/<lowercased-name>`).
 *
 * This function streamlines the URL generation process, ensuring consistent URL patterns based on different link names.
 *
 * @param navLinkName - The name of the navigation link for which the URL needs to be generated.
 * @returns string - The generated URL based on the provided navigation link name.
 *
 * @example
 * ```typescript
 * const url = generateURL('Work'); // Expected output: "/#work"
 * ```
 */
export function generateURL(navLinkName: string) {
  switch (navLinkName) {
    case 'Work':
    case 'Newsletter':
      return `/#${navLinkName.toLowerCase()}`;
    case "Let's connect":
      return 'https://www.linkedin.com/in/ryan-nono/';
    default:
      return `/${navLinkName.toLowerCase()}`;
  }
}
