/**
 * `camelCaseToSpaced` Function
 *
 * @remarks
 * The `camelCaseToSpaced` function is designed to convert a camel-cased string
 * into a spaced, lowercase representation. The primary purpose of this utility
 * is to make programmatic strings more human-readable, especially when used for
 * display purposes in user interfaces.
 *
 * ## Features:
 * 1. **Camel-Case Conversion**: Converts camel-cased strings to a spaced format.
 * 2. **Lowercase Output**: The output string is ensured to be in lowercase for consistent representation.
 *
 * @param phrase - A camel-cased string that needs to be converted.
 *
 * @returns A spaced, lowercase representation of the input camel-cased string.
 *
 * @example
 * ```tsx
 * const convertedPhrase = camelCaseToSpaced('userProfile');
 * console.log(convertedPhrase);  // Outputs: 'user profile'
 * ```
 *
 * ## Usage Notes:
 * - The function primarily targets camel-cased strings. Inputs that don't follow the camel-case
 *   convention may not yield expected results.
 * - Ensure that the input string doesn't have leading or trailing spaces for best results.
 *
 */
export function camelCaseToSpaced(phrase: string): string {
  return phrase
    .replace(/([A-Z])/g, ' $1') // Add a space before each uppercase character
    .toLowerCase();
}
