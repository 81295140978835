// External libraries
import { Variants } from 'framer-motion';

// Utils
import { ease } from '../../../utils/animations';

/**
 * Variants for the Chip component when hovered.
 * Contains two states: 'hover' and 'initial'.
 * The 'hover' state gives the Chip an opacity of 1 while the 'initial' state gives it an opacity of 0.
 * Transitions between the two states are handled with a specified duration and easing function.
 *
 * @constant
 * @type {Variants}
 */
export const hoverChipVariants: Variants = {
  hover: {
    opacity: [0, 1],
    transition: {
      duration: 0.8,
      easings: ease,
    },
  },
  initial: {
    opacity: 0,
    transition: {
      duration: 0.8,
      easings: ease,
    },
  },
};

/**
 * Variants for the Card Container when hovered.
 * Contains two states: 'hover' and 'initial'.
 * The 'hover' state applies a specified border to the Card Container while the 'initial' state makes the border transparent.
 * Transitions between the two states are handled with a specified duration and easing function.
 *
 * @constant
 * @type {Variants}
 */
export const cardContainerVariants: Variants = {
  hover: {
    border: '1px solid #696868',
    transition: {
      duration: 0.8,
      easings: ease,
    },
  },
  initial: {
    border: '1px solid transparent',
    transition: {
      duration: 0.8,
      easings: ease,
    },
  },
};
