// External libraries
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Suspense } from 'react';
import { AnimatePresence } from 'framer-motion';

// Context Providers
import AuthProvider from '../features/authentication/component/authProvider';
import CaseStudyScrollProvider from '../features/caseStudyScroll/component/caseStudyScrollProvider';
import CurrentPageContextProvider from '../features/currentPage/component/currentPageContextProvider';
import ViewportSizeContextProvider from '../features/viewportSize/component/viewportSizeContextProvider';

// Components
import Nav from './nav/nav';
import Footer from './footer/footer';
import LoadingScreen from '../components/loadingScreen/loadingScreen';

/**
 * `GlobalLayout` is the top-level layout component wrapping the entire app.
 * It orchestrates several contexts and providers ensuring global app services
 * and utilities are in place. Main parts, like `Nav` and `Outlet`, render
 * within this layout.
 *
 * @remarks
 *
 * - `AuthProvider` provides authentication functionality for the app.
 *
 * - `CurrentPageContextProvider` manages the current page's context.
 *
 * - `ScrollRestoration` restores the scroll when navigating pages.
 *
 * - `ViewportSizeContextProvider` provides viewport size context.
 *
 * - `CaseStudyScrollProvider` manages case study scroll behaviors.
 *
 * - `Nav` is the main navigation. `Outlet` renders the main content and is
 *   wrapped in a React `Suspense` to display a `LoadingScreen` during fetches.
 *
 * - `Footer` is the footer component on every page.
 *
 * @example
 * ```tsx
 * function App() {
 *   return <GlobalLayout />;
 * }
 * ```
 */
export default function GlobalLayout() {
  return (
    <AuthProvider>
      <CurrentPageContextProvider>
        <ScrollRestoration />
        <ViewportSizeContextProvider>
          <CaseStudyScrollProvider>
            <Nav />
            <AnimatePresence>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </AnimatePresence>
          </CaseStudyScrollProvider>
          <Footer />
        </ViewportSizeContextProvider>
      </CurrentPageContextProvider>
    </AuthProvider>
  );
}
