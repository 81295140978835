// External libraries
import {
  ReactNode,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

// Context
import CaseStudyScrollContext from '../context/caseStudyScrollContext';

// Hooks
import useCurrentPage from '../../currentPage/hook/useCurrentPage';
import useEagerScrollToElement from '../hooks/useEagerScrollToElement';

// Utils
import { scrollToElement } from '../../../utils/functions/scrollToElement';
import { trackDemo } from '../utils/trackDemo';
import { scrollEvent } from '../utils/scrollEvent';

// Types
import { CaseStudyScrollContextType } from '../utils/types';

/**
 * `CaseStudyScrollProvider` Component
 *
 * @remarks
 * The `CaseStudyScrollProvider` component serves as a context provider to track and control
 * the scroll position relative to specific elements in a case study page, such as a demo
 * element and a header element.
 *
 * Features:
 * - Determines whether the viewport has scrolled to a certain position relative to the
 * `demoElement`.
 * - Provides a function (`scroll`) that scrolls the viewport either to the demo or the header,
 * depending on the current scroll position.
 * - Listens to the window's scroll event (or `scrollend` if supported) to track the
 * viewport's position relative to the `demoElement`.
 * - Resets the scroll state to its initial position when the case study title changes.
 * - Exposes references to the `demoElement` and `headerElement` to children components,
 * allowing them to directly interact with these elements.
 *
 * The component uses React's Context API to expose specific properties and functions to
 * its children, making it easier for children components to be aware of and control the
 * scroll behavior related to the case study's demo and header.
 *
 * @param children - The child components to render inside the provider.
 *
 * @returns - React Element wrapped inside the `CaseStudyScrollContext.Provider`.
 *
 * @example
 * ```tsx
 * const CaseStudyPage = () => {
 *   return (
 *     <CaseStudyScrollProvider>
 *       <DemoComponent />
 *       <OtherComponents />
 *     </CaseStudyScrollProvider>
 *   );
 * }
 * ```
 *
 * Inside the child component (e.g., `DemoComponent`):
 * ```tsx
 * const DemoComponent = () => {
 *   const { demoElementRef, scroll } = useContext(CaseStudyScrollContext);
 *   return (
 *     <div ref={demoElementRef}>
 *       // ... Other content
 *       <button onClick={scroll}>Scroll to relevant section</button>
 *     </div>
 *   );
 * }
 * ```
 */
export default function CaseStudyScrollProvider({
  children,
}: {
  children: ReactNode;
}) {
  const {
    currentPage: { caseStudyTitle },
  } = useCurrentPage();

  const {
    handleTriggerClick: scrollToDemo,
    triggerRef,
    targetRef: demoElementRef,
  } = useEagerScrollToElement<HTMLElement, HTMLDivElement>(-130);

  const headerElementRef = useRef<HTMLDivElement>(null);
  const [isAtOrBelowDemo, setIsAtOrBelowDemo] = useState(false);

  const scrollElement = isAtOrBelowDemo ? 'header' : 'demo';

  const scroll = useCallback(
    async () =>
      isAtOrBelowDemo
        ? scrollToElement(headerElementRef.current)
        : scrollToDemo(),
    [isAtOrBelowDemo, scrollToDemo]
  );

  const contextValue: CaseStudyScrollContextType = useMemo(
    () => ({
      triggerRef,
      demoElementRef,
      headerElementRef,
      scrollElement,
      scroll,
    }),
    [scrollElement, scroll, triggerRef]
  );

  useEffect(() => {
    function handleScroll() {
      trackDemo(demoElementRef.current, setIsAtOrBelowDemo);
    }

    if (caseStudyTitle) {
      handleScroll();
      window.addEventListener(scrollEvent, handleScroll);
    }

    return () => {
      setIsAtOrBelowDemo(false);
      window.removeEventListener(scrollEvent, handleScroll);
    };
  }, [caseStudyTitle]);

  return (
    <CaseStudyScrollContext.Provider value={contextValue}>
      {children}
    </CaseStudyScrollContext.Provider>
  );
}
