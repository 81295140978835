/**
 * `trackDemo` Function
 *
 * @remarks
 * The `trackDemo` function is designed to determine and track the scroll position of the
 * viewport in relation to a specific `demoElement`. It checks if the viewport has scrolled
 * to, or past, a point 200 pixels above the top position of the `demoElement`.
 *
 * Once the viewport reaches this threshold, the function updates the
 * `setisAtOrBelowDemo` state with a boolean value indicating whether the viewport
 * is at or below the defined position of the `demoElement`.
 *
 * The function makes use of the `getBoundingClientRect()` method to get the position of
 * the `demoElement` relative to the viewport and then adjusts it with the `window.scrollY`
 * value to find the actual position of the element on the page.
 *
 * The function also ensures unnecessary state updates are avoided by only updating the
 * `setisAtOrBelowDemo` state if there's a change in the boolean value.
 *
 * @param demoElement - The DOM element used as a reference point to determine the
 * scroll position.
 * @param setisAtOrBelowDemo - State setter function to update whether the viewport
 * is at or below the position of the `demoElement`.
 *
 * @example
 * ```tsx
 * const demoRef = useRef<HTMLDivElement | null>(null);
 * const [isAtOrBelowDemo, setisAtOrBelowDemo] = useState(false);
 *
 * useEffect(() => {
 *   trackDemo(demoRef.current, setisAtOrBelowDemo);
 * }, [demoRef]);
 * ```
 */
export function trackDemo(
  demoElement: HTMLDivElement | null,
  setisAtOrBelowDemo: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (!demoElement) return;

  const elementBottomPosition =
    demoElement.getBoundingClientRect().top + window.scrollY;

  const newIsAtOrBelowDemo = window.scrollY + 200 >= elementBottomPosition;

  setisAtOrBelowDemo((prevIsAtOrBelowDemo) =>
    prevIsAtOrBelowDemo === newIsAtOrBelowDemo
      ? prevIsAtOrBelowDemo
      : newIsAtOrBelowDemo
  );
}
