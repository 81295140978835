// External Libraries
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

// Components
import ArrowIcon from '../../../../components/icon/arrowIcon';
import Chip from '../../../../components/chip/chip';
import Icon from '../../../../components/icon/icon';

// Hooks
import useCaseStudyScroll from '../../../../features/caseStudyScroll/hooks/useCaseStudyScroll';
import useCurrentPage from '../../../../features/currentPage/hook/useCurrentPage';
import useIsFirstPage from '../../../../hooks/useIsFirstPage';

// Utils
import { getChipProps } from './utils/functions/getChipProps';
import { isDirection } from '../../../../utils/functions/isDirection';
import { capitalize } from '../../../../utils/functions/capitalize';
import { getIconName } from './utils/functions/getIconName';
import { transition } from '../utils/navChipTransition';
import {
  lockVariants,
  arrowVariants,
  downloadVariants,
  shareVariants,
} from './utils/animationVariants';

// Styles
import '../navChip.css';

/**
 * `RightChip` Component
 *
 * @remarks
 * The `RightChip` component represents a UI element positioned on the right
 * side of a navigation or container. It dynamically adjusts its appearance and
 * behavior based on the provided context, like the current page type or viewport
 * size.
 *
 * Key Features:
 * 1. **Adaptive Behavior** - The component uses multiple hooks (`useLocation`,
 *    `useCurrentPage`, `useCaseStudyScroll`, and `useViewportSize`) to
 *    determine the correct behavior and appearance of the chip.
 *
 * 2. **Dynamic Content** - Determines its own content and properties using
 *    the `getDesktopProps` function, depending on the current page context.
 *
 * 3. **Responsive Design** - Adjusts its rendering based on the
 *    `generalViewportSize`. On larger viewports or specific page types, it
 *    displays a `Chip`, while on smaller screens, it shows a `HamburgerButton`.
 *
 * @param centerSectionRef - A reference to the center section of the navigation.
 * @returns JSX.Element - The rendered chip or hamburger button component.
 *
 * @example
 * ```tsx
 * // Usage within a parent component
 * <RightChip centerSectionRef={someRefObject} />
 * ```
 *
 * ## Usage Notes:
 * - The provided `centerSectionRef` is crucial for the `HamburgerButton`
 *   to function correctly, especially on mobile or smaller viewports.
 *
 * - This component is highly contextual and relies heavily on various hooks
 *   to determine its state and subsequently its rendered output.
 */
function RightChip() {
  const { pathname } = useLocation();

  const {
    currentPage: { pageType, caseStudyType },
  } = useCurrentPage();

  const isFirstPage = useIsFirstPage();

  const { scrollElement, scroll, triggerRef } = useCaseStudyScroll();

  const iconName = getIconName(
    isFirstPage,
    caseStudyType,
    scrollElement,
    pageType
  );

  const iconNameIsDirection = isDirection(iconName);

  return (
    <Chip
      {...getChipProps(
        isFirstPage,
        pathname,
        pageType,
        caseStudyType,
        scrollElement,
        scroll
      )}
      ref={triggerRef}
      baseStyling
      className="outerNavChip"
      animate={{ gap: iconName ? '8px' : '0px' }}
      transition={transition}
      icon={
        <motion.div
          className="animatedNavChipIconContainer"
          animate={{
            width: iconName ? (iconNameIsDirection ? '12px' : '21px') : '0px',
          }}
          transition={transition}
        >
          <Icon
            animate={iconName === 'share' ? 'show' : 'hide'}
            transition={transition}
            variants={shareVariants}
            initial="hide"
            iconName="share"
          />
          <Icon
            animate={iconName === 'download' ? 'show' : 'hide'}
            transition={transition}
            variants={downloadVariants}
            initial="hide"
            iconName="download"
          />
          <Icon
            animate={iconName === 'lockSmall' ? 'show' : 'hide'}
            transition={transition}
            variants={lockVariants}
            initial="hide"
            iconName="lockSmall"
            style={{ paddingBottom: '2px', paddingRight: '3px' }}
          />
          <ArrowIcon
            animate={
              iconNameIsDirection ? `show${capitalize(iconName)}` : 'hide'
            }
            transition={transition}
            variants={arrowVariants}
            initial="hide"
            pointDirection="top"
          />
        </motion.div>
      }
    />
  );
}

export default memo(RightChip);
