// External libraries
import { ReactNode, useCallback } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

// Components
import Chip from '../chip/chip';

// Hooks
import useViewportSize from '../../features/viewportSize/hook/useViewportSize';
import { useRefCallback } from '../../hooks/useRefCallback/useRefCallback';

// Utils
import { fadeIntoView } from '../../utils/animations';
import {
  cardContainerVariants,
  hoverChipVariants,
} from './utils/animationVariants';

// Styles
import './projectCard.css';

/**
 * `ProjectCard` is a component that renders a card with project information.
 *
 * @component
 * @param {Object} props The props that define the ProjectCard component.
 * @param {ReactNode} props.companyLogo A React element representing the company's logo.
 * @param {ReactNode} props.projectThumbnail A React element representing the thumbnail of the project.
 * @param {string} props.projectTitle The title of the project.
 * @param {string} props.projectType The type of the project.
 * @param {number | string} props.projectYear The year the project was created.
 *
 * @example
 * return (
 *   <ProjectCard
 *     companyLogo={<img src="logo.png" />}
 *     projectThumbnail={<img src="thumbnail.png" />}
 *     projectTitle="My Project"
 *     projectType="Web Application"
 *     projectYear={2023}
 *   />
 * );
 *
 * @returns {ReactNode} Returns a card with project information. The card has hover animation.
 * When the user hovers over the card, it will animate according to the 'hover' variant
 * and reset back to its 'initial' state when the hover ends.
 */
export default function ProjectCard({
  companyLogo,
  projectThumbnail,
  projectTitle,
  projectType,
  projectYear,
  to,
}: ProjectCardProps) {
  const controls = useAnimation();
  const navigate = useNavigate();
  const { desktop } = useViewportSize();
  const hover = useCallback(() => controls.start('hover'), [controls]);
  const initial = useCallback(() => controls.start('initial'), [controls]);

  const containerRef = useRefCallback<HTMLElement>(
    (node) => {
      if (desktop) {
        node.addEventListener('mouseenter', hover);
        node.addEventListener('mouseleave', initial);
      }

      return () => {
        controls.stop();
        node.removeEventListener('mouseenter', hover);
        node.removeEventListener('mouseleave', initial);
      };
    },
    [hover, initial, controls, desktop]
  );

  return (
    <motion.article
      ref={containerRef}
      className="projectCardContainer"
      onClick={() => navigate(to)}
      whileHover={desktop ? 'hover' : undefined}
      variants={cardContainerVariants}
      {...fadeIntoView({ fadeDirection: 'top' })}
    >
      <div className="cardContentBlock">
        {companyLogo}
        <div className="cardHeadingBlock">
          <div className="cardYearBlock">
            <span className="cardDateLabel">{projectYear}</span>
            <div className="cardContentDivider" />
          </div>
          <h6 className="cardH6">{projectTitle}</h6>
        </div>
      </div>

      <div className="cardThumbnailBlock">
        <Chip
          initial="initial"
          whileHover={{ border: 0 }}
          variants={hoverChipVariants}
          animate={controls}
          baseStyling
          altBG
          use="static"
          title={projectType}
          className="projectCardHoverChip"
        />
        {projectThumbnail}
      </div>
    </motion.article>
  );
}

/**
 * Props for the `ProjectCard` component.
 *
 * @property {ReactNode} companyLogo - A React element representing the company's logo.
 * @property {number} projectYear - The year the project was created.
 * @property {string} projectTitle - The title of the project.
 * @property {('Case Study' | 'Exploration Study')} projectType - The type of the project.
 * @property {ReactNode} projectThumbnail - A React element representing the thumbnail of the project.
 * @property {string} to - A string representing the URL of the project.
 */
type ProjectCardProps = {
  companyLogo: ReactNode;
  projectYear: string;
  projectTitle: string;
  projectType: string;
  projectThumbnail: ReactNode;
  to: string;
};
