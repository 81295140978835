// External libraries
import { useState, useCallback, useLayoutEffect } from 'react';

// Hooks
import useCurrentPage from '../../../../../features/currentPage/hook/useCurrentPage';
import useViewportSize from '../../../../../features/viewportSize/hook/useViewportSize';

// Utils
import { getScrollPercent } from '../../../../../utils/functions/getScrollPercent';
import { HomeSection } from '../utils/types';
import { deriveHomeState } from '../utils/functions/deriveHomeState';

/**
 * `useHomeSection` Hook
 *
 * @remarks
 * The `useHomeSection` hook is a custom hook that tracks the user's current
 * section on the homepage based on either the scroll percentage or a nav link
 * being clicked. It provides mechanisms to update the home section based on
 * interactions and scrolling events, making it useful for dynamic UI updates
 * like highlighting the active navigation link or any other visual cues.
 *
 * Key Features:
 * 1. **Scroll-based Section Tracking**: Dynamically determines the current
 *    home section based on the percentage of page scrolled using the
 *    `deriveHomeState` function.
 *
 * 2. **NavLink Interaction**: Provides a mechanism to manually set the home
 *    section based on the nav link clicked.
 *
 * 3. **Device Adaptive**: Only attaches the scroll event listener if the device
 *    is not mobile, ensuring performance and user experience optimizations.
 *
 * @returns
 * - `homeSection`: The current home section (`'Newsletter'`, `'Work'`, or `null`).
 * - `handleNavLinkClick`: A function that accepts a nav link name to update
 *                         the home section.
 *
 * @example
 * ```tsx
 * const { homeSection, handleNavLinkClick } = useHomeSection();
 * // Render components or add logic based on the `homeSection` value.
 * ```
 *
 * ## Usage Notes:
 * - Ensure that other hooks (`useViewportSize`, `useCurrentPage`, etc.) are
 *   available and functional within the project context.
 * - This hook is intended primarily for use within a home page context where
 *   the user's section needs to be tracked and updated in real-time.
 */
export default function useHomeSection() {
  // state
  const [homeSection, setHomeSection] = useState<HomeSection | null>(null);

  // hooks
  const { mobile } = useViewportSize();
  const {
    currentPage: { pageType },
  } = useCurrentPage();

  // setter function
  const updateHomeSection = useCallback(() => {
    setHomeSection((prev) => {
      const newSection = deriveHomeState(getScrollPercent());
      return newSection !== prev ? newSection : prev;
    });
  }, []);

  // scroll event listener
  useLayoutEffect(() => {
    if (pageType === 'Home' && !mobile) {
      updateHomeSection();
      document.addEventListener('scroll', updateHomeSection);
    }
    return () => document.removeEventListener('scroll', updateHomeSection);
  }, [pageType, mobile]);

  return { homeSection, updateHomeSection };
}
