/**
 * `deriveHomeState` Function
 *
 * @remarks
 * The `deriveHomeState` function is a helper function that determines the
 * current home section based on the given scroll percentage. It's a critical
 * piece of the `useHomeSection` hook, facilitating the dynamic determination
 * of the user's section as they scroll through the homepage.
 *
 * @param scrollPercent - The percentage of page that has been scrolled.
 * @returns
 * - `'Newsletter'`: If the user has scrolled more than 95% of the page.
 * - `'Work'`: If the user has scrolled more than 47% but less than 95% of the page.
 * - `null`: For any other scroll percentages.
 *
 * @example
 * ```tsx
 * const section = deriveHomeState(50);  // Returns 'Work'
 * ```
 */
export function deriveHomeState(scrollPercent: number) {
  if (scrollPercent > 95) {
    return 'Newsletter';
  }
  if (scrollPercent > 47) {
    return 'Work';
  }
  return null;
}
