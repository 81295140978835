/**
 * `getDynamicVideoObserver` Function
 *
 * This function returns an IntersectionObserver that auto-plays/pauses HTML
 * video elements when they are entirely in the viewport. If a video element
 * fully enters the viewport, it plays. When it's not fully visible, it pauses.
 *
 * The videos are always set to muted, plays inline, and loop continuously.
 *
 * @returns An IntersectionObserver tailored for HTMLVideoElements.
 *
 * @example
 * ```typescript
 * const videoObserver = getDynamicVideoObserver();
 * videoObserver.observe(document.querySelector('video'));
 * ```
 */
export function getDynamicVideoObserver() {
  return new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const video = entry.target as HTMLVideoElement;
        // Play video if it's intersecting the viewport, otherwise pause it
        video.muted = true;
        video.playsInline = true;
        video.loop = true;

        if (entry.intersectionRatio === 1) {
          video.play().catch((error) => {
            console.error(
              'Player failed to start video - this may be due to your browser settings',
              error
            );
          });
        } else {
          video.pause();
        }
      });
    },
    { threshold: 1 }
  );
}
