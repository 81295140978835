import { FormEvent, useCallback, useReducer, useRef } from 'react';
import { subscribe } from '../services/subscribe';

type FormState = { isFetching: boolean; response: string | null };

type FormAction =
  | { type: 'INITIATE_FETCH' }
  | { type: 'TERMINATE_FETCH'; payload: string | null };

export function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case 'INITIATE_FETCH':
      return { isFetching: true, response: null };
    case 'TERMINATE_FETCH':
      return { isFetching: false, response: action.payload };
    default:
      return state;
  }
}

export default function useNewsletter() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [formState, dispatch] = useReducer(formReducer, {
    isFetching: false,
    response: null,
  });

  return {
    inputRef,
    subscribe: useCallback(
      async (e: FormEvent<HTMLFormElement>) => {
        dispatch({ type: 'INITIATE_FETCH' });

        e.preventDefault();
        const email = inputRef.current?.value;

        if (!email) return;

        dispatch({ type: 'TERMINATE_FETCH', payload: await subscribe(email) });
      },
      [subscribe]
    ),
    formState,
  };
}
