// Types
import { ViewportSizeContextType } from './types';

/**
 * `computeViewportSize` Function
 *
 * @remarks
 * The `computeViewportSize` function calculates the size of the viewport
 * (specifically its width) and determines whether the current device type
 * is 'desktop' or 'mobile' based on the width.
 *
 * The breakpoint for distinguishing between 'desktop' and 'mobile' is set
 * at 768 pixels. Viewports with a width greater than 768 pixels are
 * considered 'desktop', and those 768 pixels or less are considered 'mobile'.
 *
 * @returns {ViewportSizeContextType} - An object containing:
 *   - `size`: The width of the viewport in pixels.
 *   - `device`: A string representing the device type, either 'desktop' or 'mobile'.
 *
 * @example
 * ```tsx
 * const { size, device } = computeViewportSize();
 * console.log(`The viewport width is ${size} pixels and is considered a ${device} device.`);
 * ```
 *
 * @note
 * Ensure that this function is used in an environment where `window` is defined (e.g., not during server-side rendering).
 */
export function computeViewportSize(): ViewportSizeContextType {
  const size = window.innerWidth;
  const device = size > 768 ? 'desktop' : 'mobile';
  return { size, desktop: device === 'desktop', mobile: device === 'mobile' };
}
