// External libraries
import { useContext } from 'react';

// Context
import CaseStudyScrollContext from '../context/caseStudyScrollContext';

// Types
import { CaseStudyScrollContextType } from '../utils/types';

/**
 * `useCaseStudyScroll` Hook
 *
 * @remarks
 * This custom hook provides a convenient way to access the `CaseStudyScrollContext` context.
 * It wraps around the `useContext` hook from React and specifically fetches the context data
 * provided by the `CaseStudyScrollProvider` component.
 *
 * The primary purpose of this hook is to enable components within the case study layout to
 * easily access and interact with the scrolling functionality and associated state values.
 *
 * @returns CaseStudyScrollContextType
 * The returned value is of type `CaseStudyScrollContextType`, which provides information and functions related
 * to the case study's scroll behavior.
 *
 * @example
 * ```tsx
 * function MyComponent() {
 *   const { demoElementRef, headerElementRef, scrollElement, scroll } = useCaseStudyScroll();
 *   // Use the retrieved context values and functions here...
 * }
 * ```
 */
export default function useCaseStudyScroll(): CaseStudyScrollContextType {
  return useContext(CaseStudyScrollContext);
}
