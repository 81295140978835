/**
 * Capitalizes the first letter of a given string.
 *
 * @remarks
 * This utility function takes a string and returns a new string with the
 * first character converted to uppercase, while keeping the rest of the
 * characters unchanged.
 *
 * It's useful for tasks such as formatting text or preparing string-based
 * identifiers with a standard casing convention.
 *
 * @param str - The input string to be capitalized.
 *
 * @returns A string with the first character in uppercase.
 *
 * @example
 * ```tsx
 * const capitalizedText = capitalize("hello");  // Returns "Hello"
 * ```
 */
export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
