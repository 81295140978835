// External libraries
import { RouterProvider } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

// Components
import LoadingScreen from './components/loadingScreen/loadingScreen';

// Router
import { router } from './router';

/**
 * `App` is the root component of your application.
 * It provides the BrowserRouter to your application, allowing you to use routing.
 *
 * @component
 *
 * @returns {ReactNode} Returns the root element of your application.
 */
export default function App() {
  return (
    <AnimatePresence>
      <RouterProvider router={router} fallbackElement={<LoadingScreen />} />
    </AnimatePresence>
  );
}
