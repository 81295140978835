// External Libraries
import { motion, useAnimation } from 'framer-motion';
import { useRef, memo, RefObject, useMemo, useEffect } from 'react';

// Components
import RightChip from './sectionChips/rightChip/rightChip';
import CenterChips from './sectionChips/centerChips/centerChips';
import LeftChip from './sectionChips/leftChip/leftChip';
import HamburgerButton from '../../components/hamburgerButton/hamburgerButton';

// Hooks
import useCurrentPage from '../../features/currentPage/hook/useCurrentPage';
import useViewportSize from '../../features/viewportSize/hook/useViewportSize';
import useIsFirstPage from '../../hooks/useIsFirstPage';

// Utilities
import {
  globalNavAnimation,
  sideSectionAnimation,
  centerSectionVariants,
} from './utility/navSectionAnimations';
import { shouldShowRightChip } from './utility/functions/shouldShowRightChip';
import { getCenterVariant } from './utility/functions/getCenterVariant';

// Types
import { MenuToggleProps } from '../../components/hamburgerButton/hook/useMenuToggle';

// Styles
import './nav.css';

/**
 * `Nav` Component
 *
 * @remarks
 * The `Nav` component provides a structured navigation for the application.
 * It consists of left, center, and right sections, with each section containing
 * various UI elements like chips or buttons.
 *
 * Key Features:
 * 1. **Adaptive Animations**: Based on the device type (mobile or desktop), the navigation
 *    elements exhibit different animations. On desktop, the center section's animation
 *    variant is determined by the current page type and other conditions. Meanwhile,
 *    on mobile, a hamburger menu button provides a toggle functionality for the center section.
 *
 * 2. **Responsive Sections**: The `leftSection` and `rightSection` have fixed contents
 *    (LeftChip and RightChip/HamburgerButton respectively). The center section's visibility
 *    varies depending on the current page and viewport.
 *
 * 3. **High Performance**: Leveraging the `memo` HOC, the component ensures that unnecessary
 *    re-renders are minimized. Furthermore, hooks such as `useCallback` and `useMemo` are
 *    utilized to optimize the computation and memory usage.
 *
 * 4. **Ref Propagation**: The `centerSectionRef` allows interaction with the center section
 *    from child components like `HamburgerButton`.
 *
 * @returns JSX.Element - The fully rendered navigation component.
 *
 * @example
 * ```tsx
 * // Basic usage within a parent component
 * <Nav />
 * ```
 *
 * ## Usage Notes:
 * - Ensure `framer-motion` library is installed and correctly set up as animations
 *   are pivotal for this component.
 * - While integrating into a project, be cautious of the animation states and ensure
 *   required hooks and utilities (`useCurrentPage`, `useViewportSize`, etc.) are properly
 *   implemented.
 */
function Nav() {
  // References
  const centerSectionRef = useRef<HTMLDivElement>(null);

  // Hooks for utilities, states and animation
  const {
    currentPage: { pageType, caseStudyType },
  } = useCurrentPage();
  const { desktop } = useViewportSize();
  const isFirstPage = useIsFirstPage();
  const animationController = useAnimation();

  // Mobile animation configurations
  const mobileControls: MenuToggleProps['controls'] = useMemo(
    () => ({
      showFn: () => animationController.start('mobileShow'),
      hideFn: () => animationController.start('mobileHide'),
      isOpenFn: (menuRef: RefObject<HTMLDivElement>) =>
        menuRef.current?.style.opacity === '1',
    }),
    [animationController]
  );

  // Desktop animation configurations
  const showRightChip = shouldShowRightChip(desktop, caseStudyType, pageType);
  const centerVariant = getCenterVariant(caseStudyType, pageType, isFirstPage);

  useEffect(() => {
    if (desktop) animationController.start(centerVariant);
  }, [desktop, animationController, centerVariant]);

  return (
    <motion.nav {...globalNavAnimation} className="navContainer" id="nav">
      <motion.section {...sideSectionAnimation} className="leftSection">
        <LeftChip />
      </motion.section>

      <motion.section
        initial={'hide'}
        animate={animationController}
        variants={centerSectionVariants}
        className="centerSection"
        ref={centerSectionRef}
      >
        <CenterChips />
      </motion.section>

      <motion.section {...sideSectionAnimation} className="rightSection">
        {showRightChip ? (
          <RightChip />
        ) : (
          <HamburgerButton
            menuRef={centerSectionRef}
            controls={mobileControls}
          />
        )}
      </motion.section>
    </motion.nav>
  );
}

export default memo(Nav);
