/**
 * The `getScrollPercent` function calculates the current scroll percentage of
 * the webpage. It takes into account the scrollTop and scrollHeight of the
 * document, as well as the height of the viewport, to determine what percentage
 * of the document has been scrolled through.
 *
 * @function
 * @returns {number} The current scroll percentage of the webpage.
 */
export function getScrollPercent() {
  const h = document.documentElement;
  const b = document.body;
  const st = 'scrollTop';
  const sh = 'scrollHeight';

  const scrollTop = h[st] || b[st] || 0;
  const scrollHeight = h[sh] || b[sh] || 0;
  const clientHeight = h.clientHeight || 0;

  const denominator = scrollHeight - clientHeight;

  if (denominator === 0) {
    return 0; // return 0% if there's no scrolling possible
  }

  return (scrollTop / denominator) * 100;
}
