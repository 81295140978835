// External libraries
import { createContext, createRef } from 'react';

// Types
import { CaseStudyScrollContextType } from '../utils/types';

const CaseStudyScrollContext = createContext<CaseStudyScrollContextType>({
  triggerRef: () => {},
  scrollElement: 'demo',
  headerElementRef: createRef<HTMLDivElement | null>(),
  demoElementRef: createRef<HTMLDivElement | null>(),
  scroll: async () => {},
});

export default CaseStudyScrollContext;
