// External libraries
import { Variants } from 'framer-motion';

/**
 * `centerChipVariants`
 *
 * An object specifying the variants of the navigation chips. Each variant
 * describes the chip's style and appearance based on different states:
 *
 * - `selected`: The appearance when the chip is corresponding to the current
 *               section or page.
 * - `hover`: The appearance when the chip is hovered over.
 * - `default`: The chip's default appearance.
 */
export const centerChipVariants: Variants = {
  selected: {
    fontWeight: 700,
    background: 'var(--colorGradient)',
  },
  hover: {
    fontWeight: 500,
    background:
      'linear-gradient(90deg, rgba(141, 141, 141, 1), rgba(141, 141, 141, 1))',
    borderColor: 'rgba(255, 255, 255, 0)',
  },
  default: {
    fontWeight: 500,
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))',
  },
};
