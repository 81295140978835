// Components
import ContentContainer from '../containers/contentContainer/contentContainer';

// Styles
import './label.css';

export default function label({
  labelText,
  subtle,
}: {
  labelText: string;
  subtle?: boolean;
}) {
  return (
    <ContentContainer
      semanticType="span"
      animated
      className={`label ${subtle ? '' : 'textGradient'}`}
    >
      {labelText}
    </ContentContainer>
  );
}
