/**
 * Array of standard navigation links that are used across the application.
 * The contents of this array are readonly due to the 'as const' assertion.
 *
 * @constant
 * @type {readonly string[]}
 */
export const standardNavLinks = [
  'Work',
  'About',
  'Resume',
  'Newsletter',
] as const;

/**
 * Array of navigation links that are used specifically for mobile views.
 * This array includes all of the standard navigation links, plus an additional "Let's connect" link.
 * The contents of this array are readonly due to the 'as const' assertion.
 *
 * @constant
 * @type {readonly string[]}
 */
export const mobileNavLinks = [...standardNavLinks, "Let's connect"] as const;

/**
 * Array of navigation links that are used specifically for case study views.
 * This array includes all of the mobile navigation links, excluding the first one.
 * The contents of this array are readonly due to the 'as const' assertion.
 *
 * @constant
 * @type {readonly string[]}
 */
export const caseStudyNavLinks = [...mobileNavLinks.slice(1)] as const;
