// External libraries
import { useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * `useIsFirstPage` is a custom hook that determines if the current page is
 * the very first one the user has landed on, based on pathname changes and
 * certain redirection logic.
 *
 * Rules of the hook:
 * 1. If the current path hasn't changed from the previous path, the page
 *    remains as the first page.
 * 2. If a user is redirected from an 'authRerouter', the page isn't
 *    considered as a new page. But, the previous path value is updated
 *    for the next checks.
 * 3. If neither condition is satisfied, the hook will determine if there's
 *    a need to adjust the `isFirstPage` state.
 *
 * @returns {boolean} - A boolean value that indicates if the current page
 *                      is the first one the user has visited.
 *
 * @example
 * ```tsx
 * const isFirstPage = useIsFirstPage();
 * ```
 */
export default function useIsFirstPage() {
  const [isFirstPage, setIsFirstPage] = useState(true);
  const { pathname, state: locationState } = useLocation();
  const prevPathname = useRef(pathname);

  useLayoutEffect(() => {
    // If the path is the same, skip further logic.
    if (pathname === prevPathname.current) {
      return;
    }

    // if redirected from authRerouter
    // don't count as new page
    // but update prev path val for next checks
    if (locationState?.from === 'authRerouter') {
      prevPathname.current = pathname;
      return;
    }

    // adjust state if necessary
    setIsFirstPage((prevIsFirstPage) => {
      if (prevIsFirstPage) {
        prevPathname.current = pathname;
        return false;
      }
      return prevIsFirstPage;
    });
  }, [pathname, locationState]);

  return isFirstPage;
}
