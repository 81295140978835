// External librairies
import { LottieRefCurrentProps } from 'lottie-react';
import {
  // eslint-disable-next-line @typescript-eslint/no-redeclare
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';

/**
 * `useMenuToggle` Hook
 *
 * @remarks
 * This custom hook manages the toggle behavior of a dropdown or side menu.
 * It not only provides the core toggling logic but also manages side-effects,
 * such as listening to outside clicks to auto-collapse the menu or handling animations
 * through Lottie, if provided.
 *
 * The hook returns a `toggleMenu` function which can be used to manually toggle the state
 * of the menu, and the current state of the menu `isExpanded` (true if opened, false if closed).
 *
 * Key Features:
 * 1. **Lottie Integration**: The hook integrates with a Lottie animation player if provided,
 *    controlling the direction of the animation based on the state of the menu.
 *
 * 2. **Outside Click Detection**: The hook listens to clicks on the document. If a click is
 *    detected outside the menu, it will automatically collapse the menu.
 *
 * 3. **Flexible Control Functions**: Custom show, hide, and check-open functions can be passed
 *    to the hook, allowing it to adapt to different implementation details of the menu.
 *
 * @param props - The props to configure the hook.
 * @param props.controls - Control functions for showing, hiding, and checking if the menu is open.
 * @param props.menuRef - A reference to the menu element, used for outside-click detection.
 * @param props.lottiePlayerRef - (Optional) A reference to a Lottie player for menu animations.
 *
 * @returns
 * - `toggleMenu`: A function to manually toggle the menu's state.
 * - `isExpanded`: A boolean representing the current state of the menu.
 *
 * @example
 * ```tsx
 * const { toggleMenu, isExpanded } = useMenuToggle({
 *   menuRef: myMenuRef,
 *   controls: {
 *     showFn: () => ...,
 *     hideFn: () => ...,
 *     isOpenFn: (ref) => ...
 *   },
 *   lottiePlayerRef: myLottieRef
 * });
 * ```
 */
export default function useMenuToggle({
  controls: { hideFn, isOpenFn, showFn },
  menuRef,
  lottiePlayerRef,
}: MenuToggleProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleMenu = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation();
      setIsExpanded((currentIsExpanded) => {
        if (lottiePlayerRef) {
          if (!lottiePlayerRef.current) return currentIsExpanded;

          lottiePlayerRef.current.setSpeed(5);
          lottiePlayerRef.current.setDirection(currentIsExpanded ? -1 : 1);
          lottiePlayerRef.current.play();
        }

        if (currentIsExpanded) hideFn();
        else showFn();

        return !currentIsExpanded;
      });
    },
    [hideFn, showFn]
  );

  const handleDocumentClick = useCallback(
    (e: Event) => {
      if (!menuRef.current) return;

      const outSideMenuClick = !menuRef.current.contains(e.target as Node);
      const menuItemClick = (e.target as Element).closest('.menuItem');

      if (outSideMenuClick) {
        if (isOpenFn(menuRef)) toggleMenu();
      } else if (menuItemClick) {
        toggleMenu();
      }
    },
    [isOpenFn, toggleMenu]
  );

  useEffect(() => {
    hideFn();
    document.addEventListener('click', handleDocumentClick);
    return () => document.removeEventListener('click', handleDocumentClick);
  }, []);

  return { toggleMenu, isExpanded };
}

export type MenuToggleProps = {
  menuRef: RefObject<HTMLDivElement>;
  controls: {
    showFn: VoidFunction;
    hideFn: VoidFunction;
    isOpenFn: (menuRef: RefObject<HTMLDivElement>) => boolean;
  };
  lottiePlayerRef?: RefObject<LottieRefCurrentProps>;
};
