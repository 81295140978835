// Types
import { CurrentPage } from '../../../utils/types';
import { HANDLES } from './HANDLES';

/**
 * `computeCurrentPage` Function
 *
 * @remarks
 * Computes the details of the current page based on the provided pathname,
 * handle, and authentication state.
 *
 * Key Functionality:
 * 1. **Case Study Identification**: Determines if the current pathname
 *    is indicative of a preview or full case study.
 *
 * 2. **Page Type Prediction**: Depending on the pathname, makes an
 *    optimistic guess about the current page type.
 *
 * 3. **Page Type Finalization**: Utilizes the handle and authentication state
 *    to determine the exact type of the page the user is on.
 *
 * 4. **Case Study Title Derivation**: Extracts the case study title from
 *    the pathname if on a case study page.
 *
 * @param pathname - Current URL pathname to determine the nature of the page.
 * @param handle - Handle associated with the route (might determine content protection).
 * @param hasAuth - Boolean indicating if the user is authenticated or not.
 *
 * @returns CurrentPage - An object detailing the type of the current page,
 * the type of the case study (if applicable), and the title of the case study.
 *
 * @example
 * ```tsx
 * const currentPage = computeCurrentPage('/case-study/example/full', HANDLE.PROTECTED, true);
 * console.log(currentPage); // Outputs: { pageType: 'CaseStudy', caseStudyType: 'full', caseStudyTitle: 'Example' }
 * ```
 *
 * ## Usage Notes:
 * - This function assumes that certain path structures exist in your application.
 *   Ensure that the function's assumptions match your routing configuration.
 * - The function considers authentication state and handles to deduce the correct page type.
 * - Any changes in the URL structure, particularly case study URLs, may require updates to this function.
 */
export function computeCurrentPage(
  pathname: string,
  handle: unknown,
  hasAuth: boolean
): CurrentPage {
  let isFullOrPreviewCaseStudy = false;
  let caseStudyType: CurrentPage['caseStudyType'];
  let optimisticPageType: CurrentPage['pageType'];
  let pageType: CurrentPage['pageType'];
  let caseStudyTitle: CurrentPage['caseStudyTitle'];

  // compute if on full on preview case study
  if (pathname.endsWith('preview')) {
    isFullOrPreviewCaseStudy = true;
    caseStudyType = 'preview';
  } else if (pathname.endsWith('full')) {
    isFullOrPreviewCaseStudy = true;
    caseStudyType = 'full';
  } else {
    caseStudyType = undefined;
  }

  // compute page type - for if we had auth
  if (isFullOrPreviewCaseStudy) {
    optimisticPageType = 'CaseStudy';
  } else if (['/', ''].includes(pathname)) {
    optimisticPageType = 'Home';
  } else if (pathname.includes('about')) {
    optimisticPageType = 'About';
  } else if (pathname.includes('resume')) {
    optimisticPageType = 'Resume';
  } else if (pathname.includes('password')) {
    optimisticPageType = 'Password';
  } else {
    optimisticPageType = '404';
  }

  // compute actual page type
  if (
    optimisticPageType === 'CaseStudy' &&
    handle !== HANDLES.UNPROTECTED &&
    !hasAuth &&
    caseStudyType !== 'preview'
  ) {
    pageType = 'Loading';
  } else {
    pageType = optimisticPageType;
  }

  // compute case study title
  if (isFullOrPreviewCaseStudy) {
    caseStudyTitle = pathname
      .slice(12, pathname.lastIndexOf('/'))
      .replace(/-|\b\w/g, (match) =>
        match === '-' ? ' ' : match.toUpperCase()
      );
  } else {
    caseStudyTitle = undefined;
  }

  return {
    pageType,
    caseStudyType,
    caseStudyTitle,
  };
}
