// External libraries
import {
  ButtonHTMLAttributes,
  FormHTMLAttributes,
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import LottiePlayer from 'lottie-react';

// Components
import ArrowIcon from '../icon/arrowIcon';

// Assets
import LoadingAnimation from '../../assets/lotties/loadingProgressLottie.json';

// Styles
import './inputForm.css';

/**
 * `InputForm` Component
 *
 * @remarks
 * The `InputForm` is a reusable form component tailored for scenarios with a single
 * input field and a button. It incorporates responsive feedback through either displaying
 * a loading animation or an arrow icon.
 *
 * Key Features:
 * 1. **Flexibility**: Accepts properties for the form, input, and button elements
 *    to cater to diverse use cases.
 * 2. **Forward Ref**: The component forwards the ref to the input element, allowing
 *    parent components to directly access the DOM element.
 * 3. **Dynamic Loading**: Renders a loading animation inside the button based on the `isLoading` prop.
 *
 * ## Structure:
 * - A single input field.
 * - A submit button, which either shows a loading animation or an arrow icon.
 *
 * @params props - Contains properties for the form (`formProps`), input field (`inputProps`),
 * and button (`buttonProps`). It also includes an `isLoading` prop to determine the button's inner content.
 *
 * @params ref - A forwarded ref that attaches to the input element.
 *
 * @returns JSX Element that contains the single field form setup.
 *
 * @example
 * ```tsx
 * function App() {
 *   const inputRef = useRef<HTMLInputElement>(null);
 *
 *   return (
 *     <InputForm
 *       ref={inputRef}
 *       inputProps={{ placeholder: 'Type here...', type: 'text' }}
 *       buttonProps={{ onClick: handleSubmit }}
 *     />
 *   );
 * }
 * ```
 *
 * ## Usage Notes:
 * - Ensure the necessary dependencies (`LottiePlayer`, `ArrowIcon`) are imported.
 * - You can override styles by passing the `className` attribute within the `formProps`,
 *   `inputProps`, or `buttonProps`.
 * - For accessibility reasons, it's advised to provide necessary attributes, especially for the `input`.
 */
function InputForm(
  { buttonProps, formProps, inputProps, isLoading }: InputFormProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <form
      {...formProps}
      className={`oneFieldForm ${formProps?.className ?? ''}`}
    >
      <input
        {...inputProps}
        ref={ref}
        className={`inputField ${inputProps?.className ?? ''}`}
      />
      <button
        {...buttonProps}
        className={`inputSubmit ${buttonProps?.className ?? ''}`}
      >
        {isLoading ? (
          <LottiePlayer
            animationData={LoadingAnimation}
            autoplay
            loop
            className="player"
          />
        ) : (
          <ArrowIcon pointDirection="right" />
        )}
      </button>
    </form>
  );
}

type InputFormProps = {
  isLoading?: boolean;
  formProps?: FormHTMLAttributes<HTMLFormElement>;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
};

export default forwardRef<HTMLInputElement, InputFormProps>(InputForm);
