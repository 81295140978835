// External libraries
import { Variants } from 'framer-motion';

/**
 * Common transition property for all animation variants.
 *
 * @constant
 * @type {object}
 */
const transition = { duration: 0.5, ease: 'anticipate' };

/**
 * Variants for parent animation.
 *
 * @constant
 * @type {Variants}
 */
export const parentVariants: Variants = {
  hover: {
    // variant for when mouse enters
    transition,
    scale: 1,
    visibility: 'visible',
    opacity: [0, 1, 1, 1],
    x: 0,
    y: 0,
  },
  rest: {
    // variant for when mouse is over
    transition,
    scale: 1,
    visibility: 'visible',
    opacity: 1,
    x: 0,
    y: 0,
  },
  initial: {
    // initial variant (no mouse over the element)
    transition,
    scale: 0,
    x: -15,
    y: -15,
    opacity: 0,
    transitionEnd: {
      visibility: 'hidden',
    },
  },
};

/**
 * Variants for child animation.
 *
 * @constant
 * @type {Variants}
 */
export const childVariants: Variants = {
  fadeIn: { opacity: 1, x: 0, transition: { delay: 0.3 } },
  initial: { opacity: 0, x: 20 },
};
