// External libraries
import { ReactNode, useCallback, useMemo, useReducer } from 'react';

// Context
import { AuthContext } from '../context/authContext';

// Utils
import { authReducer } from '../utils/reducer';
import { getAccess } from '../services/getAccess';
import { login } from '../services/login';

/**
 * `AuthProvider` Component
 *
 * @remarks
 * The `AuthProvider` component serves as a context provider for the authentication state
 * and actions associated with authentication processes like login and access retrieval.
 *
 * It makes use of the `useReducer` hook to maintain and manage the authentication state.
 * This state contains information such as whether the authentication data has been fetched,
 * if an authentication process is currently in progress, if the user is authenticated,
 * and the user's ID.
 *
 * The component also defines callback functions `handleLogin` and `handleGetAccess` for
 * the login and access retrieval processes, respectively. These functions are memoized using
 * the `useCallback` hook to ensure stability across re-renders.
 *
 * The `AuthProvider` component utilizes the `useMemo` hook to optimize the context value,
 * ensuring that the value passed to the context provider does not change unless necessary.
 *
 * Child components wrapped by `AuthProvider` can access the authentication state and
 * associated actions via the `AuthContext`.
 *
 * @param props - Props for the `AuthProvider` component.
 * @param props.children - React child components that will have access to the authentication context.
 *
 * @returns The `AuthContext.Provider` component wrapping the children.
 *
 * @example
 * ```tsx
 * <AuthProvider>
 *   <App />
 * </AuthProvider>
 * ```
 */
export default function AuthProvider(props: { children: ReactNode }) {
  const [auth, dispatch] = useReducer(authReducer, {
    authFetched: false,
    isFetchingAuth: false,
    hasAuth: false,
    id: '',
  });

  const handleLogin = useCallback(
    (password: string) => login(password, auth.isFetchingAuth, dispatch),
    [auth.isFetchingAuth]
  );

  const handleGetAccess = useCallback(
    () => getAccess(auth.id, auth.isFetchingAuth, dispatch),
    [auth.id, auth.isFetchingAuth]
  );

  const contextValue = useMemo(
    () => ({ auth, login: handleLogin, getAccess: handleGetAccess }),
    [auth, handleLogin, handleGetAccess]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
}
