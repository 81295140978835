// External Libraries
import { memo } from 'react';

// Components
import Chip from '../../../../components/chip/chip';

// Hooks
import useCurrentPage from '../../../../features/currentPage/hook/useCurrentPage';
import useViewportSize from '../../../../features/viewportSize/hook/useViewportSize';
import useHomeSection from './hook/useHomeSection';

// Utils
import { generateURL } from './utils/functions/generateURL';
import { getCurrentSelection } from './utils/functions/getCurrentSelection';
import { transition } from '../utils/navChipTransition';
import { mobileNavLinks, standardNavLinks } from './utils/tuples';
import { centerChipVariants } from './utils/animationVariants';

/**
 * `CenterChips`
 *
 * A component responsible for displaying the central navigation chips on the
 * screen. The chips' appearance and behavior vary based on the current page
 * type, home state, and device viewport size.
 *
 * Each chip changes its style and function depending on:
 * 1. Whether it's currently selected (matching the page or home state).
 * 2. Whether it's being hovered over.
 * 3. Its default appearance.
 *
 * When a chip corresponding to either 'Work' or 'Newsletter' is clicked, it
 * manually sets the home state to the respective section.
 *
 * @returns A JSX fragment containing the list of navigation chips.
 */
function CenterChips() {
  const {
    currentPage: { pageType },
  } = useCurrentPage();

  const { homeSection } = useHomeSection();
  const { mobile } = useViewportSize();
  const currentSelection = getCurrentSelection(pageType, homeSection, mobile);
  const navLinks = mobile ? mobileNavLinks : standardNavLinks;

  return (
    <>
      {navLinks.map((navLinkName, index) => (
        <Chip
          variants={centerChipVariants}
          initial="default"
          whileHover={navLinkName === currentSelection ? 'selected' : 'hover'}
          animate={navLinkName === currentSelection ? 'selected' : 'default'}
          transition={transition}
          key={index}
          title={navLinkName}
          use="anchor"
          baseStyling={false}
          to={generateURL(navLinkName)}
          target={navLinkName === "Let's connect" ? '_blank' : '_self'}
          className="default menuItem"
        />
      ))}
    </>
  );
}

export default memo(CenterChips);
