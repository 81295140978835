// Lib
import axiosInstance from '../../../lib/axios';

// Services
import { refresh } from './refresh';

// Utils
import { getErrorMessage } from '../../../utils/functions/getErrorMessage';
import { Action } from '../utils/types';

/**
 * Fetches access credentials and manages authentication state.
 *
 * @remarks
 * Initiates an authentication process for the user based on the given ID.
 * If authentication is already in progress (`isFetchingAuth` is `true`),
 * the function will exit early. Otherwise, it'll dispatch an action to
 * indicate the start of the authentication process.
 *
 * The function then tries to request access via `accessRequest()`.
 * If successful, it compares the returned ID with the one provided.
 * If they're different, it'll dispatch a 'SET_ID' action to update the ID.
 *
 * If the `accessRequest()` fails, the function will attempt to refresh the
 * credentials using `refresh()`. On a successful refresh, it dispatches
 * a 'SET_ID' action with the new ID. If the refresh fails as well, it'll
 * dispatch a 'SET_ID' action with an empty string to indicate the lack of valid credentials.
 *
 * Once all operations (success or failure) are completed, the function will
 * dispatch a 'SET_AUTH_FETCHED' action to indicate the end of the authentication process.
 *
 * @param id - Initial ID to compare with the response ID.
 * @param isFetchingAuth - Flag indicating if an authentication process is already underway.
 * @param dispatch - Dispatch function for the authentication state management.
 *
 * @example
 * ```tsx
 * const id = "12345";
 * const isFetchingAuth = false;
 * getAccess(id, isFetchingAuth, dispatch);
 * ```
 */
export async function getAccess(
  currentId: string,
  isFetchingAuth: boolean,
  dispatch: React.Dispatch<Action>
) {
  if (isFetchingAuth) return;

  dispatch({ type: 'INITIATE_AUTH_FETCH' });

  try {
    const { data: responseId } = await axiosInstance.get('/auth/access');

    if (currentId !== responseId) {
      dispatch({ type: 'SET_ID', payload: responseId });
    }
  } catch (accessErr) {
    console.error('Access request failed:', getErrorMessage(accessErr));
    try {
      const responseId = await refresh();
      dispatch({ type: 'SET_ID', payload: responseId });
    } catch (refreshErr) {
      console.error('Refresh request failed:', getErrorMessage(refreshErr));
      dispatch({ type: 'SET_ID', payload: '' });
    }
  } finally {
    dispatch({ type: 'SET_AUTH_FETCHED', payload: true });
  }
}
