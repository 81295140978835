/**
 * `scrollToElement` Function
 *
 * @remarks
 * The `scrollToElement` function provides a utility to smoothly scroll the viewport
 * to the specified HTML element. It is especially useful for enhancing user
 * navigation within web applications.
 *
 * ## Features:
 * 1. **Element Targeting**: Directly scrolls to the targeted HTML element.
 * 2. **Y-Offset Adjustment**: Provides the capability to adjust the final scroll position
 *    with a specified Y-offset, enabling flexible positioning.
 * 3. **Smooth Behavior**: Offers a smooth scrolling experience, enhancing UX.
 *
 * @param element - The target HTML element to which the viewport should scroll.
 * @param yOffset - (Optional) An offset along the Y-axis to adjust the final scrolling position. Default is `0`.
 *
 * @returns Void (The function does not return any value).
 *
 * @example
 * ```tsx
 * const btn = document.querySelector('.scrollBtn');
 * const targetElement = document.getElementById('section2');
 *
 * btn?.addEventListener('click', () => {
 *   scrollToElement(targetElement, -10);  // Scrolls to 'section2' with a 10-pixel offset above it
 * });
 * ```
 *
 * ## Usage Notes:
 * - The function requires an HTML element as its target. If a `null` value or a non-existent element
 *   is provided, the function will exit without any effect.
 * - The Y-offset can be used for precise positioning, e.g., to ensure headers aren't
 *   overlaying the content after the scroll.
 *
 */
export function scrollToElement(element: HTMLElement | null, yOffset = 0) {
  if (!element) return;

  const rect = element.getBoundingClientRect();
  const top = rect.top + window.pageYOffset + yOffset;

  window.scrollTo({
    top,
    behavior: 'smooth',
  });
}
