// External libraries
import { motion } from 'framer-motion';
import { memo } from 'react';

// Components
import Icon from '../../components/icon/icon';
import Chip from '../../components/chip/chip';

// Hooks
import useCurrentPage from '../../features/currentPage/hook/useCurrentPage';

// Utils
import { getBreadcrumb } from './utils/getBreadCrumb';
import { getSocialLinks } from './utils/getSocialLinks';

// Styles
import './footer.css';

/**
 * The `Footer` component represents the footer section of a webpage. It displays a footer navigation
 * bar that includes logo, breadcrumb navigation (if the page is not 'Home'), some text, and links to social media accounts.
 *
 * The `Footer` component uses framer-motion for animations such as the initial fade-in effect and hover scale effects.
 * The animation parameters are controlled with properties like 'initial', 'animate', 'whileHover', and 'transition'.
 *
 * The component uses the memo function from React to prevent unnecessary re-renders, optimizing performance.
 *
 * @component
 *
 * @param {CurrentPage} currentPage - An object containing information about the current page.
 *
 * @example
 * // Example usage of Footer component in a parent component
 * <Footer currentPage={currentPage} />
 *
 * @returns {ReactNode} Returns the Footer component which includes navigation and social links.
 */
function Footer() {
  const { currentPage } = useCurrentPage();
  const breadcrumb = getBreadcrumb(currentPage);
  const socialLinks = getSocialLinks();

  return (
    <motion.footer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeIn', duration: 1.5 }}
      className="footerContainer"
    >
      <div className="footerChip">
        <Chip
          to="../#"
          icon={<Icon animated={1.1} iconName="creativeRankSmall" />}
          className="footerLogo"
          use="anchor"
        />
        {currentPage?.pageType !== 'Home' && breadcrumb}
      </div>

      <div className="footerRightSection">
        <div className="footerText">
          Designed and built by Ryan Nono using <br />
          [figma, typescript, express.js, postgress, react]
        </div>

        <div className="footerRightDivider" />

        <div className="footerSocialsContainer">{socialLinks}</div>
      </div>
    </motion.footer>
  );
}

export default memo(Footer);
