// External libraries
import LottiePlayer from 'lottie-react';
import { motion } from 'framer-motion';

// Assets
import LoadingAnimation from '../../assets/lotties/loadingProgressLottie.json';

// Styles
import './loadingScreen.css';

/**
 * `LoadingScreen` Component
 *
 * @remarks
 * The `LoadingScreen` component is a visual feedback UI element that displays
 * an animated loading state to the user. The component leverages the `motion.div`
 * from the `framer-motion` library for smooth opacity transitions as the
 * component enters or exits the view. It uses the `LottiePlayer` to play a
 * specified Lottie animation, which provides an engaging loading visual.
 *
 * Key Features:
 * 1. **Smooth Transitions**: The component fades in with an opacity transition
 *    when initialized and fades out when it exits.
 *
 * 2. **Lottie Animation**: Utilizes the `LottiePlayer` for a rich and
 *    customizable loading animation experience.
 *
 * 3. **Auto-play and Loop**: The Lottie animation auto-plays and continuously
 *    loops until the component is removed.
 *
 * @example
 * ```tsx
 * // Use the LoadingScreen component as a placeholder while content is loading
 * { isLoading ? <LoadingScreen /> : <Content />}
 * ```
 *
 * ## Usage Notes:
 * - Make sure to have the `framer-motion` library installed and set up in your
 *   project to make use of the `motion.div` transitions.
 * - Ensure the `LottiePlayer` component and the `LoadingAnimation` data are
 *   available and correctly imported.
 */
export default function LoadingScreen() {
  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 1.5 }}
      id="loadingAnimationContainer"
    >
      <LottiePlayer
        animationData={LoadingAnimation}
        autoplay
        loop
        id="loadingScreenPlayer"
      />
    </motion.div>
  );
}
