import axiosInstance from '../../../lib/axios';
import { getErrorMessage } from '../../../utils/functions/getErrorMessage';

export async function subscribe(email: string) {
  try {
    const { data } = await axiosInstance.post('/newsletter', { email });
    const message = data?.message;
    return typeof message === 'string' ? message : null;
  } catch (subError) {
    return getErrorMessage(subError);
  }
}
