// Types
import { CurrentPage } from '../../../../../../utils/types';

/**
 * `getCurrentSelection` Function
 *
 * @remarks
 * The `getCurrentSelection` function determines the current selection based on the
 * provided page type and home state. It's primarily designed for improved navigation
 * logic within an application.
 *
 * Key Features:
 * 1. **Home State Resolution** - If the current page type is "Home", it resolves the
 *    selection based on the home state, which can be either "Work", "Newsletter", or `null`.
 *
 * 2. **PageType Resolution** - For certain page types like "About" and "Resume", the
 *    function directly returns the page type as the selection.
 *
 * 3. **Fallback Return** - For any other scenarios, the function returns `null`.
 *
 * @param pageType - Represents the type of the current page.
 * @param homeState - Represents the home state which can be either 'Work', 'Newsletter', or `null`.
 *
 * @returns 'Work' | 'Newsletter' | 'About' | 'Resume' | null - The current selection.
 *
 * @example
 * ```
 * const selection = getCurrentSelection('Home', 'Work');
 * console.log(selection); // Outputs: "Work"
 * ```
 *
 * ## Usage Notes:
 * - This function should be used in contexts where you want to determine the current
 *   navigation state or highlight based on the user's current location within the app.
 */
export function getCurrentSelection(
  pageType: CurrentPage['pageType'],
  homeSection: 'Work' | 'Newsletter' | null,
  isMobile: boolean
) {
  if (isMobile) return null;
  if (pageType === 'Home') return homeSection;
  if (['About', 'Resume'].includes(pageType)) return pageType;
  return null;
}
