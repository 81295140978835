import { Variants } from 'framer-motion';

export const shareVariants: Variants = {
  show: {
    position: 'static',
    opacity: 1,
    scale: 1,
    rotate: '0deg',
  },
  hide: {
    position: 'absolute',
    opacity: 0,
    scale: 0.7,
    rotate: '315deg',
  },
};

export const downloadVariants: Variants = {
  show: {
    position: 'static',
    opacity: 1,
    rotate: '360deg',
    scale: 1,
  },
  hide: {
    position: 'absolute',
    opacity: 0,
    rotate: '225deg',
    scale: 1.3,
  },
};

export const lockVariants: Variants = {
  show: {
    position: 'static',
    opacity: 1,
  },
  hide: {
    position: 'absolute',
    opacity: 0,
  },
};

export const arrowVariants: Variants = {
  showRight: {
    position: 'static',
    opacity: 1,
    rotate: '90deg',
    backgroundColor: 'transparent',
  },
  showTop: {
    position: 'static',
    opacity: 1,
    rotate: '0deg',
    backgroundColor: 'transparent',
  },
  showBottom: {
    position: 'static',
    opacity: 1,
    rotate: '180deg',
  },
  hide: {
    position: 'absolute',
    opacity: 0,
    rotate: '0deg',
  },
};
