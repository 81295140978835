// External libraries
import { createBrowserRouter } from 'react-router-dom';
import { lazy } from 'react';

// Layouts
import GlobalLayout from './layouts/globalLayout';

// Components
import Home from './pages/home/home';

// Utils
import { HANDLES } from './features/currentPage/utils/HANDLES';

const About = lazy(() => import('./pages/about/about'));
const Resume = lazy(() => import('./pages/resume/resume'));
const AuthRerouter = lazy(
  () => import('./components/authRerouter/authRerouter')
);
const Password = lazy(() => import('./pages/altPages/password/password'));
const ObservationLog = lazy(() => import('./pages/caseStudies/observationLog'));
const ProximityAlerts = lazy(
  () => import('./pages/caseStudies/proximityAlerts')
);
const StreamingAR = lazy(() => import('./pages/caseStudies/streamingAR'));
const IntakeForm = lazy(() => import('./pages/caseStudies/intakeForm'));
const PageNotFound = lazy(
  () => import('./pages/altPages/pageNotFound/pageNotFound')
);

/**
 * Router Configuration
 *
 * @remarks
 * This configuration manages the routing for the application. It uses
 * React Router's `createBrowserRouter` utility and defines the layout structure
 * and route elements for each path.
 *
 * ## Features:
 * - **Code Splitting**: Implements lazy-loading to improve initial load time
 *   by splitting code at different routes.
 * - **Global Layout**: All routes are encapsulated with a `GlobalLayout`
 *   component for a consistent interface.
 * - **Main Routes**: Defines paths for Home, About, Resume, and a collection
 *   of Case Studies.
 * - **Case Study Routes**: Specific sub-routes are provided for different
 *   case studies. Some of these utilize the `AuthRerouter` for authentication
 *   and further nested routes for various case study views or password prompts.
 * - **Fallback Route**: Uses a dedicated `PageNotFound` component for unmatched
 *   routes.
 *
 * @example
 * ```tsx
 * function App() {
 *   return (
 *     <Routes>
 *       <Route path="/" element={<Home />} />
 *       ...
 *     </Routes>
 *   );
 * }
 * ```
 *
 * ## Dependencies:
 * - `react-router-dom`: Provides routing capabilities.
 * - `react`: Enables lazy-loading of components for better performance.
 *
 * ## Usage Notes:
 * - Ensure the `createBrowserRouter` utility is correctly set up.
 * - Components imported lazily might show a loading spinner or fallback UI
 *   while the component chunk is being fetched.
 * - Adjust the paths and components as necessary to reflect any changes
 *   in application structure or requirements.
 *
 */
export const router = createBrowserRouter([
  {
    path: '/',
    element: <GlobalLayout />,
    children: [
      { index: true, element: <Home /> },
      { path: 'about', element: <About /> },
      { path: 'resume', element: <Resume /> },
      {
        path: 'case-study',
        children: [
          {
            path: 'palantir-observation-log',
            element: <AuthRerouter />,
            children: [
              { path: 'full', element: <ObservationLog type="full" /> },
              { path: 'preview', element: <ObservationLog type="preview" /> },
              { path: 'password', element: <Password /> },
            ],
          },
          {
            path: 'palantir-proximity-alerts',
            element: <AuthRerouter />,
            children: [
              { path: 'full', element: <ProximityAlerts type="full" /> },
              { path: 'preview', element: <ProximityAlerts type="preview" /> },
              { path: 'password', element: <Password /> },
            ],
          },
          {
            path: 'exploring-streaming-AR/full',
            element: <StreamingAR />,
            handle: HANDLES.UNPROTECTED,
          },
          {
            path: 'turbotax-cs-intake-form/full',
            element: <IntakeForm />,
            handle: HANDLES.UNPROTECTED,
          },
        ],
      },
      { path: '*', element: <PageNotFound /> },
    ],
  },
]);
