// External libraries
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

/**
 * `MotionLink` Component
 *
 * @remarks
 * The `MotionLink` component provides an animation-ready variant of the `Link`
 * component. By wrapping the `Link` component with `motion`, it allows for
 * smoother transitions and animations in line with the Framer Motion library's
 * capabilities.
 *
 * Using `MotionLink` is recommended when there's a need to animate the `Link`
 * component on certain events or interactions, such as hover, click, or page
 * transitions.
 *
 * The underlying `Link` component is a routing mechanism provided by
 * React Router.
 *
 * @example
 * ```tsx
 * <MotionLink to="/about" whileHover={{ scale: 1.05 }}>
 *   About Us
 * </MotionLink>
 * ```
 */
const MotionLink = motion(Link);

export type MotionLinkProps = React.ComponentPropsWithRef<typeof MotionLink>;

export default MotionLink;
