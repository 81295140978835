// Components
import Chip from '../../../components/chip/chip';
import Icon from '../../../components/icon/icon';

// Types
import { CurrentPage } from '../../../utils/types';

/**
 * Returns the breadcrumb based on the current page information.
 *
 * @param {CurrentPage} currentPage - The current page information.
 * @returns {string} The breadcrumb.
 */
export function getBreadcrumb({ pageType, caseStudyTitle }: CurrentPage) {
  const breadcrumbTitle = pageType === 'CaseStudy' ? caseStudyTitle : pageType;

  return (
    <>
      <Icon iconName="breadcrumbArrow" />
      <Chip
        baseStyling={false}
        use="anchor"
        to="#"
        title={breadcrumbTitle}
        className="breadcrumb"
      />
    </>
  );
}
