// Utils
import { CurrentPage } from '../../../../utils/types';

/**
 * Determines the center section variant based on multiple conditions.
 *
 * This function is responsible for deciding the initial animation state of the
 * center section in the navigation, based on the viewport's device type, the
 * current page type, and whether the page is the first one the user is viewing.
 *
 * @param {boolean} isDesktop - Indicates if the current viewport corresponds to a desktop device.
 * @param {CurrentPage['caseStudyType']} caseStudyType - Represents the type of case study (if any) currently being viewed.
 * @param {CurrentPage['pageType']} pageType - Represents the type of page currently being viewed.
 * @param {boolean} isFirstPage - Flag indicating whether the user is on the first page.
 *
 * @returns
 * - 'show': Default state for mobile viewports, or subsequent pages on desktop.
 * - 'hide': For case study pages or when the page type is 'Password' on desktop.
 * - 'initialShow': For the initial page the user views on desktop.
 */
export function getCenterVariant(
  caseStudyType: CurrentPage['caseStudyType'],
  pageType: CurrentPage['pageType'],
  isFirstPage: boolean
) {
  if (caseStudyType || pageType === 'Password') return 'hide';
  if (isFirstPage) return 'initialShow';
  return 'show';
}
