// External Libraries
import { memo } from 'react';
import { motion } from 'framer-motion';

// Components
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '../../../../components/icon/arrowIcon';
import Chip from '../../../../components/chip/chip';
import Icon from '../../../../components/icon/icon';

// Hooks
import useIsFirstPage from '../../../../hooks/useIsFirstPage';
import useCurrentPage from '../../../../features/currentPage/hook/useCurrentPage';

// Utils
import { transition } from '../utils/navChipTransition';

// Styles
import '../navChip.css';
import { homeVariants, backVariants } from './utils/animationVariants';

/**
 * `LeftChip` Component
 *
 * @remarks
 * The `LeftChip` component serves dual functionality on the navigation bar:
 *
 * Key Features:
 * 1. **Dynamic Display & Text**:
 *    - On pages of type 'Password' or 'CaseStudy':
 *      - If it's the first page, it shows the home icon and the text "Ryan Nono".
 *      - Otherwise, it displays the back arrow icon with the text "Go back".
 *    - On all other page types, it will always show the home icon with the text "Ryan Nono".
 *
 * 2. **Navigation**:
 *    - The chip functions as a button that can navigate users back to the home page or
 *      to the previous page.
 *    - The exact behavior is based on the current page's context.
 *
 * @returns JSX.Element - A chip component that dynamically adjusts its appearance and behavior.
 *
 * @example
 * ```
 * // To render the leftmost chip of the navbar
 * <LeftChip />
 * ```
 *
 * ## Usage Notes:
 * - This component leverages `framer-motion` for smooth transitions between its states.
 * - It uses several hooks like `useCurrentPage` and `useIsFirstPage` to determine the
 *   current navigation state and the appropriate rendering.
 */
function LeftChip() {
  const {
    currentPage: { pageType },
  } = useCurrentPage();
  const navigate = useNavigate();
  const isFirstPage = useIsFirstPage();
  const showHomeChip =
    pageType === 'Password' || pageType === 'CaseStudy' ? isFirstPage : true;

  return (
    <Chip
      title={showHomeChip ? 'Ryan Nono' : 'Go back'}
      use="button"
      iconPosition="left"
      baseStyling
      className="outerNavChip"
      onClick={() => (showHomeChip ? navigate('/') : navigate(-1))}
      icon={
        <motion.div
          className="animatedNavChipIconContainer"
          animate={{ width: showHomeChip ? '34px' : '12px' }}
          transition={transition}
        >
          <Icon
            animate={showHomeChip ? 'show' : 'hide'}
            transition={transition}
            style={{ transformOrigin: 'center' }}
            initial="show"
            variants={homeVariants}
            iconName="creativeRank"
          />
          <ArrowIcon
            animate={!showHomeChip ? 'show' : 'hide'}
            transition={transition}
            style={{ transformOrigin: 'center' }}
            initial="hide"
            variants={backVariants}
            pointDirection="left"
          />
        </motion.div>
      }
    />
  );
}

export default memo(LeftChip);
