// External libraries
import { useRef, HTMLAttributes, ReactNode } from 'react';
import { motion, useAnimation } from 'framer-motion';

// Components
import Icon from '../icon/icon';

// Hooks
import useViewportSize from '../../features/viewportSize/hook/useViewportSize';
import { useRefCallback } from '../../hooks/useRefCallback/useRefCallback';

// Utils
import { parentVariants, childVariants } from './utils/animationVariants';
import { tooltipRefEffect } from './utils/tooltipRefEffect';

// Styles
import './toolTip.css';

/**
 * `ToolTip` component that shows a tooltip when its parent element is hovered
 * over. The tooltip uses animations, and the animation behavior is determined
 * based on the viewport size (desktop or mobile).
 *
 * Internally, the component attaches event listeners to the parent element
 * for hover effects and uses the `useRefCallback` to manage these listeners.
 * The animations for the tooltip and its children are managed by the
 * `useAnimation` hook and the `motion` component.
 *
 * @param {ToolTipProps} props - Properties passed to the tooltip component.
 * @param {React.ReactNode} props.children - React nodes that form the
 *        content of the tooltip.
 * @param {string} [props.className] - Additional class name to be appended
 *        to the tooltip container for styling.
 * @param {...any} rest - Rest of the properties passed to the `figcaption`
 *        element.
 *
 * @returns {JSX.Element} A tooltip element wrapped around the given children.
 */
export default function ToolTip({
  children,
  className,
  ...rest
}: ToolTipProps) {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();
  const { desktop } = useViewportSize();
  const attachedElements = useRef(new Set<HTMLElement>());
  const containerRef = useRefCallback(
    (node) =>
      tooltipRefEffect(node, attachedElements, tooltipRef, controls, desktop),
    [controls, desktop]
  );

  return (
    <figcaption
      className={`tooltipContainer ${className ?? ''}`}
      ref={containerRef}
      {...rest}
    >
      <motion.div
        ref={tooltipRef}
        initial="initial"
        variants={parentVariants}
        animate={controls}
        className="tooltipContentContainer"
      >
        <motion.div
          variants={childVariants}
          animate={controls}
          className="childAnimationContainer"
        >
          {children}
        </motion.div>
      </motion.div>
      <Icon
        iconName="info"
        initial={{ scale: 0, opacity: 0 }}
        whileInView={{ scale: 1, opacity: [0, 1, 1] }}
        viewport={{ once: true }}
        transition={{ duration: 1.5, ease: 'anticipate' }}
      />
    </figcaption>
  );
}

/**
 * `ToolTipProps` is a type definition for the properties that can be passed to the `ToolTip` component.
 * It includes standard HTML attributes for a `div` element, as well as the `children` property for the tooltip content.
 *
 *
 * @property {ReactNode} children - The content to be displayed within the tooltip. This is usually a JSX element or text.
 * @property {HTMLAttributes<HTMLDivElement>} - Standard HTML attributes for a `div` element.
 *  This can include inline styles, CSS classes, data attributes, or event handlers, among other properties.
 */
type ToolTipProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;
