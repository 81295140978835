// Components
import Icon from '../../../components/icon/icon';
import MotionLink from '../../../components/motionLink/motionLink';

// Data
import { socialLinksData } from '../../../data/footer';

/**
 * Generates an array of MotionLink components for rendering social links.
 * Each MotionLink encapsulates an Icon representing a particular social media
 * platform. The social links' data is sourced from `socialLinksData`.
 *
 * @returns {JSX.Element[]} An array of MotionLink components corresponding to
 *          each social media link in `socialLinksData`.
 *
 * @example
 * const socialLinksComponents = getSocialLinks();
 * // Rendered as:
 * // [<MotionLink ...><Icon .../></MotionLink>, ...]
 */
export function getSocialLinks() {
  return socialLinksData.map(({ href, iconName, webp }, index) => (
    <MotionLink
      key={index}
      whileHover={{ scale: 1.2 }}
      to={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon iconName={iconName} webp={webp} />
    </MotionLink>
  ));
}
