// External libraries
import { useMemo, useRef, useCallback } from 'react';

// Hooks
import useViewportSize from '../../viewportSize/hook/useViewportSize';
import { useRefCallback } from '../../../hooks/useRefCallback/useRefCallback';
import useCurrentPage from '../../currentPage/hook/useCurrentPage';

// Utils
import { scrollToElement } from '../../../utils/functions/scrollToElement';
import { startLoad } from '../utils/startLoad';

/**
 * `useEagerScrollToElement` Hook
 *
 * This hook provides utilities for eager loading of images between a trigger
 * element and a target element when the trigger is hovered ( on desktop).
 * Then, a provided callback can be used to scroll to the target element
 * when the trigger is clicked.
 *
 * @param offset - (Optional) Scroll offset when using the scrollToElement
 *                 function.
 *
 * @returns An object containing:
 *   1. `setTriggerRef`: A ref-setting function for the triggering element.
 *   2. `handleTriggerClick`: A callback to scroll to the target when the
 *      trigger is clicked.
 *   3. `targetRef`: A ref for the target element to scroll to.
 *
 * @example
 * ```typescript
 * const { setTriggerRef, handleTriggerClick, targetRef } =
 *      useEagerScrollToElement<HTMLElement, HTMLElement>();
 *
 * <div ref={setTriggerRef} onClick={handleTriggerClick}>Trigger</div>
 * <div ref={targetRef}>Target Content</div>
 * ```
 *
 * Usage Notes:
 * - Hovering over the trigger element starts loading images between the
 *   trigger and the target.
 * - Clicking on the trigger scrolls to the target element.
 */
export default function useEagerScrollToElement<
  Trigger extends HTMLElement,
  Target extends HTMLElement,
>(offset = 0) {
  const {
    currentPage: { caseStudyTitle },
  } = useCurrentPage();
  const { mobile } = useViewportSize();

  const targetRef = useRef<Target>(null);
  const controllerRef = useRef<AbortController | null>(null);

  const handleLoad = useCallback(
    () => startLoad(controllerRef, targetRef, offset),
    [offset]
  );

  const triggerRef = useRefCallback<Trigger>(
    (node) => {
      if (caseStudyTitle && !mobile) {
        node.addEventListener('mouseenter', handleLoad, { once: true });
      }

      return () => {
        controllerRef.current?.abort();
        node.removeEventListener('mouseenter', handleLoad);
      };
    },
    [caseStudyTitle, mobile, handleLoad]
  );

  const handleTriggerClick = useCallback(
    () => scrollToElement(targetRef.current, offset),
    [offset]
  );

  return useMemo(
    () => ({
      triggerRef,
      handleTriggerClick,
      targetRef,
    }),
    [triggerRef, handleTriggerClick]
  );
}
