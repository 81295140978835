// External libraries
import { createContext } from 'react';

// Types
import { ViewportSizeContextType } from '../utils/types';

export const ViewportSizeContext = createContext<ViewportSizeContextType>({
  desktop: true,
  mobile: false,
  size: NaN,
});
