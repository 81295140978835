// Types
import { CurrentPage } from '../../../../../../utils/types';

/**
 * `getIconName` Function
 *
 * @remarks
 * The `getIconName` function computes the name of an icon based on the context
 * of the current page. This icon name can be used to render the appropriate
 * visual representation for various scenarios within the app.
 *
 * Key Features:
 * 1. **Case Study Preview** - Returns a lock icon for pages that are just previews.
 *
 * 2. **Full Case Study** - Depending on the scroll position (`scrollElement`), the function
 *    returns either a top or bottom icon.
 *
 * 3. **Password Page** - Returns a right arrow icon.
 *
 * 4. **Fallback** - If no condition matches, it will return `null`, indicating
 *    there's no icon for the given parameters.
 *
 * @param caseStudyType - Represents the type of case study page: `preview` or `full`.
 * @param scrollElement - Indicates the scroll position or element in focus: `header` or `demo`.
 * @param pageType - Represents the type of the current page, like `Password`.
 *
 * @returns string | null - The name of the icon or `null` if there's no applicable icon.
 *
 * @example
 * ```
 * // To get the icon for a preview case study
 * const iconName = getIconName('preview', 'header', 'CaseStudy');
 * ```
 *
 * ## Usage Notes:
 * - Always ensure to provide all the required parameters to get accurate results.
 * - The function is primarily designed for case study pages but also caters to other
 *   page types like `Password`.
 */
export function getIconName(
  isFirstPage: boolean,
  caseStudyType: CurrentPage['caseStudyType'],
  scrollElement: 'header' | 'demo',
  pageType: CurrentPage['pageType']
) {
  if (
    (pageType === 'Loading' && isFirstPage && caseStudyType === 'full') ||
    pageType === 'Password'
  ) {
    return 'right';
  }

  if (pageType === 'CaseStudy') {
    if (caseStudyType === 'full') {
      if (scrollElement === 'header') return 'top';
      return 'bottom';
    }
    return 'lockSmall';
  }

  if (pageType === 'Resume') return 'download';

  return 'share';
}
