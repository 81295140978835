// External libraries
import { ReactNode, useCallback, useEffect, useState } from 'react';

// Context
import { ViewportSizeContext } from '../context/viewportSizeContext';

// Utils
import { computeViewportSize } from '../utils/computeViewportSize';

// Types
import { ViewportSizeContextType } from '../utils/types';

/**
 * `ViewportSizeContextProvider` Component
 *
 * @remarks
 * The `ViewportSizeContextProvider` is a React context provider component
 * that gives its children access to the current size of the viewport and
 * the corresponding device type (either 'desktop' or 'mobile').
 *
 * It leverages the `computeViewportSize` function to determine the viewport's
 * size and device type, and automatically updates the context value whenever
 * the window is resized.
 *
 * @param children - React children elements that will have access to the viewport size context.
 *
 * @example
 * ```tsx
 * import { ViewportSizeContext } from './path-to-your-context';
 *
 * function MyApp() {
 *   return (
 *     <ViewportSizeContextProvider>
 *       <MyComponent />
 *     </ViewportSizeContextProvider>
 *   );
 * }
 *
 * function MyComponent() {
 *   const viewport = useContext(ViewportSizeContext);
 *   return <div>Current device: {viewport.device}</div>;
 * }
 * ```
 *
 * @note
 * - The breakpoint for distinguishing between 'desktop' and 'mobile' is 768 pixels.
 * - Ensure that this component wraps around components that intend to use the `ViewportSizeContext`.
 * - Do not use the context outside of the `ViewportSizeContextProvider` tree as it will be undefined.
 */
export default function ViewportSizeContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [viewportSize, setViewportSize] = useState<ViewportSizeContextType>(
    computeViewportSize()
  );

  const handleResize = useCallback(
    () => setViewportSize(computeViewportSize()),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Return the state and setter as an array
  return (
    <ViewportSizeContext.Provider value={viewportSize}>
      {children}
    </ViewportSizeContext.Provider>
  );
}
