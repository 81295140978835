// Lib
import { axiosStatic, getCancelToken } from '../../../lib/axios';
import axiosInstance from '../../../lib/axios';

/**
 * `loadImagesBetween` Function
 *
 * This function is responsible for the eager-loading of images between the current
 * scroll position and a specified target element. It makes sure to fetch
 * appropriate image resolutions based on the device's pixel ratio. It also
 * provides a mechanism to abort the loading process using an `AbortSignal`.
 *
 * @param element - The target HTML element to which we're considering the
 *                  distance to eager load images.
 * @param abortSignal - The signal to abort the loading process.
 * @param offset - (Optional) An offset value to adjust the target position.
 *
 * @returns A Promise that resolves when all images are loaded.
 *
 * @example
 * ```typescript
 * const abortController = new AbortController();
 * loadImagesBetween(targetElement, abortController.signal);
 * // To abort the loading process:
 * // abortController.abort();
 * ```
 */
export async function loadImagesBetween(
  element: HTMLElement,
  abortSignal: AbortSignal,
  offset = 0
) {
  // Cache current scroll position and target position
  const currentScrollPosition = window.scrollY;
  const targetPosition =
    element.getBoundingClientRect().top + currentScrollPosition + offset;

  // Get device pixel ratio
  const dpr = window.devicePixelRatio || 1;
  let resolution: number;
  if (dpr >= 2) resolution = 2;
  else resolution = 1;

  // Get all lazy-loaded images on the page
  const images = Array.from(
    document.querySelectorAll<HTMLImageElement>('img[loading="lazy"]')
  );

  // Filter only the images between the current position and target
  const imagesToLoad = images.filter((img) => {
    const imgPosition = img.getBoundingClientRect().top + currentScrollPosition;
    return (
      imgPosition > currentScrollPosition &&
      imgPosition <= targetPosition &&
      !img.complete
    );
  });

  const loadPromises: Promise<void>[] = imagesToLoad.map((img) => {
    if (img.dataset.eagerLoaded) return Promise.resolve();

    // Modify the src to point to the appropriate resolution image
    const appropriateSrc = img.src.replace('-4x.', `-${resolution}x.`);

    return new Promise<void>((resolve) => {
      const onLoadOrError = () => {
        img.removeEventListener('load', onLoadOrError);
        img.removeEventListener('error', onLoadOrError);
        resolve();
      };

      abortSignal.addEventListener('abort', () => {
        img.removeEventListener('load', onLoadOrError);
        img.removeEventListener('error', onLoadOrError);
        resolve();
      });

      img.addEventListener('load', onLoadOrError);
      img.addEventListener('error', onLoadOrError);

      //fetch appropriate resolution img
      axiosInstance
        .get(appropriateSrc, {
          responseType: 'blob',
          cancelToken: getCancelToken((c) => {
            abortSignal.addEventListener('abort', () =>
              c('Image fetch aborted')
            );
          }),
        })
        .then((response) => {
          const reader = new FileReader();
          reader.onloadend = function () {
            if (reader.result) {
              img.src = reader.result as string;
              img.setAttribute('loading', 'eager');
              img.dataset.eagerLoaded = 'true'; // Mark the image as eager-loaded
            }
          };
          reader.readAsDataURL(response.data);
        })
        .catch((error) => {
          if (!axiosStatic.isCancel(error)) {
            console.error('Image fetch failed:', error);
          }
        });
    });
  });

  // Wait for all images between the current position and the target to finish loading
  return Promise.all(loadPromises);
}
