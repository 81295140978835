// Types
import { TooltipAnimationFunctions } from './animationFunctions';

/**
 * Manages event listeners for tooltip hover/click actions. This function is
 * responsible for adding or removing event listeners from an HTML element based
 * on the given action. It can accommodate both desktop and mobile event
 * scenarios.
 *
 * @param {HTMLElement} hoverElement - The element on which the tooltip hover or
 *        click event will be triggered.
 * @param {boolean} isDesktop - Determines whether the current viewport is
 *        desktop-sized. Influences the type of events attached to the
 *        `hoverElement`.
 * @param {TooltipAnimationFunctions} animationFunctions - Object containing
 *        animation functions (e.g. `start`, `maintain`, `reset`, `toggle`).
 * @param {'add' | 'remove'} action - The action to be taken, either to "add"
 *        or "remove" event listeners.
 * @param {React.MutableRefObject<Set<HTMLElement>>} attachedElementsSet -
 *        A mutable ref object containing a set of elements that already have
 *        the tooltip event listeners attached.
 */
export function handleEventListeners(
  hoverElement: HTMLElement,
  isDesktop: boolean,
  animationFunctions: TooltipAnimationFunctions,
  action: 'add' | 'remove',
  attachedElementsSet: React.MutableRefObject<Set<HTMLElement>>
) {
  const { maintain, reset, start, toggle } = animationFunctions;
  if (action === 'add' && !attachedElementsSet.current.has(hoverElement)) {
    if (isDesktop) {
      hoverElement.addEventListener('mouseenter', start);
      hoverElement.addEventListener('mouseover', maintain);
      hoverElement.addEventListener('mouseleave', reset);
    } else hoverElement.addEventListener('click', toggle);
    attachedElementsSet.current.add(hoverElement);
  } else if (attachedElementsSet.current.has(hoverElement)) {
    hoverElement.removeEventListener('mouseenter', start);
    hoverElement.removeEventListener('mouseover', maintain);
    hoverElement.removeEventListener('mouseleave', reset);
    hoverElement.removeEventListener('click', toggle);
    attachedElementsSet.current.delete(hoverElement);
  }
}
