// External libraries
import { ReactNode, useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Context
import { CurrentPageContext } from '../context/currentPageContext';

// Hooks
import { useAuth } from '../../authentication/hook/useAuth';

// Utils
import { computeCurrentPage } from '../utils/computeCurrentPage';

// Services
import { capitalize } from '../../../utils/functions/capitalize';

/**
 * `CurrentPageContextProvider` Component
 *
 * @remarks
 * The `CurrentPageContextProvider` component wraps its children and provides
 * them with access to the current page's context using the `CurrentPageContext`.
 *
 * Key Features:
 * 1. **Path Extraction**: Fetches the current route's `pathname` and `handle`
 *    from the last match in the routing hierarchy.
 *
 * 2. **Authentication Dependency**: Uses the `useAuth` hook to determine if
 *    the user has authenticated or not. This authentication state affects
 *    the result of the current page computation.
 *
 * 3. **Memoized Computation**: Uses `useMemo` to efficiently re-compute the
 *    current page's details only when the `pathname` or authentication state changes.
 *
 * @params children: ReactNode - The child components that require access
 * to the current page's context.
 *
 * @returns JSX.Element - Wraps the children with the `CurrentPageContext.Provider`,
 * providing them access to the current page's context.
 *
 * @example
 * ```tsx
 * <CurrentPageContextProvider>
 *   <MyComponent />
 * </CurrentPageContextProvider>
 * ```
 *
 * ## Usage Notes:
 * - Ensure to wrap the parts of your application that require knowledge of the
 *   current page within this component.
 * - The child components can then utilize the `CurrentPageContext` to access the
 *   current page's details.
 * - The current page computation might depend on certain routes and their
 *   associated handles, so ensure they're configured appropriately.
 */
export default function CurrentPageContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { handle, pathname } = useMatches().at(-1)!;
  const {
    auth: { hasAuth },
  } = useAuth();

  const contextValue = useMemo(
    () => ({ currentPage: computeCurrentPage(pathname, handle, hasAuth) }),
    [pathname, handle, hasAuth]
  );

  const {
    currentPage: { pageType, caseStudyTitle, caseStudyType },
  } = contextValue;

  const title = caseStudyTitle
    ? `${caseStudyTitle} - ${capitalize(caseStudyType!)}`
    : pageType === 'Home'
    ? 'Ryan Nono - Product Designer & Full Stack Web Developer'
    : pageType;

  // Return the state and setter as an array
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
      </Helmet>
      <CurrentPageContext.Provider value={contextValue}>
        {children}
      </CurrentPageContext.Provider>
    </>
  );
}
