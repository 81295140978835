// Types
import { ChipProps } from '../../../../../../components/chip/utils/types';
import { CurrentPage } from '../../../../../../utils/types';

export function getChipProps(
  isFirstPage: boolean,
  pathname: string,
  pageType: CurrentPage['pageType'],
  caseStudyType: CurrentPage['caseStudyType'],
  scrollElement: 'header' | 'demo',
  scroll: () => Promise<void>
): Readonly<ChipProps> {
  if (
    (pageType === 'Loading' && isFirstPage && caseStudyType === 'full') ||
    pageType === 'Password'
  ) {
    return {
      use: 'anchor',
      replace: true,
      to: pathname.replace('password', 'preview'),
      iconPosition: 'right',
      title: 'View preview',
    };
  }

  if (pageType === 'CaseStudy') {
    if (caseStudyType === 'full') {
      return {
        use: 'button',
        onClick: scroll,
        iconPosition: 'left',
        title: scrollElement === 'header' ? 'Back to the top' : 'Skip to demo',
      };
    }
    return {
      use: 'anchor',
      replace: true,
      to: pathname.replace('preview', 'password'),
      iconPosition: 'left',
      title: 'Unlock full project',
    };
  }

  if (pageType === 'Resume') {
    return {
      use: 'anchor',
      to: '/resume/RyanNonoResume.pdf',
      target: '_blank',
      download: true,
      iconPosition: 'right',
      title: 'Download resume',
    };
  }

  return {
    use: 'anchor',
    to: 'https://www.linkedin.com/in/ryan-nono/',
    target: '_blank',
    iconPosition: 'right',
    rel: 'noopener noreferrer',
    title: "Let's connect",
  };
}
