import { Variants } from 'framer-motion';

export const backVariants: Variants = {
  show: {
    rotate: '270deg',
    position: 'static',
    left: '0px',
    scale: 1,
    opacity: 1,
  },
  hide: {
    rotate: '0deg',
    position: 'absolute',
    left: '6px',
    scale: 1.55,
    opacity: 0,
  },
};

export const homeVariants: Variants = {
  show: {
    rotate: '0deg',
    position: 'static',
    left: '0px',
    scale: 1,
    opacity: 1,
    width: '100%',
  },
  hide: {
    rotate: '270deg',
    position: 'absolute',
    left: '-6px',
    scale: 0.45,
    opacity: 0,
    width: '45%',
  },
};
